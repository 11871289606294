import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  Autocomplete,
  Button,
  LegacyCard,
  ContextualSaveBar,
  FormLayout,
  Frame,
  Icon,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  TextField,
  Text,
  Toast,
  Banner,
  Tooltip,
  Checkbox,
  Scrollable,
  EmptyState,
  LegacyStack,
  UnstyledLink,
  Select,
  Thumbnail,
  DropZone,
  HorizontalStack,
  Card,
  Tag,
} from '@shopify/polaris';

import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';

import axios from 'axios';

import './VariantDetails.scss';
import { TopBarMarkup, NavigationMarkup, contextControlMarkup } from '../../../../../components';

import { CancelSmallMinor, FraudProtectMajor, NoteMinor, PlusMinor, QuestionMarkMajor, SearchMinor } from '@shopify/polaris-icons';
import { SortableItemWarranty, SortableItemQuotation, SortableItemPricingRule } from '../../Components';

import { PricingRule, QuotationField, PricingTable, Variant, Company, Warranty, Renewal } from '../../../../../types';
import { useUser } from '../../../../../utils/PrivateRoute';
import { useParams } from 'react-router-dom';
import EditableTable from '../../Components/EditableTable/EditableTable';
import EditableRows from '../../Components/EditableTable/EditableRows';
import { TColumnContent } from '../../../../../types/editableTable';

type AutocompleteCompanyOption = {
  label: string;
  value: string;
  company: Company;
};

export function VariantDetails() {
  const { user } = useUser();
  const params = useParams();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [update, setUpdate] = useState(false);

  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const [loadError, setLoadError] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: boolean) => {
    setMobileNavigationActive(!data);
  };

  /**
   * Drag & Drop quotation fields
   */
  const [quotationOptions, setQuotationOptions] = useState(['1']);
  const sensorsQuotation = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEndQuotation(event: any) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setQuotationOptions((options) => {
        const oldIndex = options.indexOf(active.id);
        const newIndex = options.indexOf(over.id);

        return arrayMove(options, oldIndex, newIndex);
      });
    }
  }

  /**
   * Drag & Drop pricing rules
   */
  const [pricingRulesOptions, setPricingRulesOptions] = useState(['1']);
  const sensorsPricingRules = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEndPricingRules(event: any) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setPricingRulesOptions((options) => {
        const oldIndex = options.indexOf(active.id);
        const newIndex = options.indexOf(over.id);

        return arrayMove(options, oldIndex, newIndex);
      });
    }
  }

  /**
   * Drag & Drop
   */
  const [warranties, setWarranties] = useState(['1']);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setWarranties((warranties) => {
        const oldIndex = warranties.indexOf(active.id);
        const newIndex = warranties.indexOf(over.id);

        return arrayMove(warranties, oldIndex, newIndex);
      });
    }
  }

  /**
   * Variant states
   */
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  // Variant type
  const [selectedVariantType, setSelectedVariantType] = useState('basic');
  const variantTypeOptions = [
    { label: 'Base', value: 'basic' },
    { label: 'API', value: 'api' },
  ];

  // Api identifier
  const [apiIdentifier, setApiIdentifier] = useState('');

  // Group policy
  const [groupPolicy, setGroupPolicy] = useState(false);

  // To be quoted
  const [toBeQuoted, setToBeQuoted] = useState(false);

  // Direct emission
  const [directEmission, setDirectEmission] = useState(false);

  // Bank Transfer
  const [bankTransfer, setBankTransfer] = useState(false);

  // Individual sale
  const [individualSale, setIndividualSale] = useState(false);
  const [quoteEmailTemplate, setQuoteEmailTemplate] = useState('');
  const [policyEmailTemplate, setPolicyEmailTemplate] = useState('');
  const [renewalEmailTemplate, setRenewalEmailTemplate] = useState('');

  // Company
  const [deselectedCompaniesOptions, setDeselectedCompaniesOptions] = useState<AutocompleteCompanyOption[]>([]);
  const [selectedCompanyOptions, setSelectedCompanyOptions] = useState<string[]>([]);
  const [companyInputValue, setCompanyInputValue] = useState('');
  const [companyOptions, setCompanyOptions] = useState(deselectedCompaniesOptions);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [company, setCompany] = useState<Company | null>(null);

  // Quotation
  const [quotation, setQuotation] = useState(false);
  const [groupPolicyBroker, setGroupPolicyBroker] = useState('');
  const [groupPolicyBranch, setGroupPolicyBranch] = useState('');
  const [groupPolicyNumber, setGroupPolicyNumber] = useState('');
  const [groupPolicyInclusionNumber, setGroupPolicyInclusionNumber] = useState('');

  const [price, setPrice] = useState('');
  const [salePrice, setSalePrice] = useState('');

  // Quotation fields
  const quotationFieldsType = [
    { label: 'Data', value: 'date' },
    { label: 'Array', value: 'array' },
    { label: 'Numero', value: 'number' },
    { label: 'Percentuale', value: 'percentage' },
    { label: 'Valuta', value: 'currency' },
    { label: 'Testo', value: 'text' },
    { label: 'Checkbox', value: 'boolean' },
    { label: 'Select', value: 'select' },
  ];

  const [quotationFields, setQuotationFields] = useState<QuotationField[]>([]);

  // Pricing table
  const [pricingTable, setPricingTable] = useState<PricingTable>({
    row: '',
    row_labels: [],
    column: '',
    column_labels: [],
    values: [],
    is_range: false,
  });
  const [hasPricingTable, setHasPricingTable] = useState(true);
  const [pricingTableExists, setPricingTableExists] = useState(false);
  const [rows, setRows] = useState<string>('');
  const [columns, setColumns] = useState<string>('');

  const handleRowsChange = useCallback((e: string) => {
    setRows(e);
  }, []);

  const handleColumnsChange = useCallback((e: string) => {
    setColumns(e);
  }, []);

  const [columnLabels, setColumnLabels] = useState<Array<TColumnContent>>([{ columnType: 'text', columnTitle: '' }]);
  const [rowLabels, setRowsLabels] = useState<Array<Array<any>>>([]);

  // Commission
  const [hasCommission, setHasCommission] = useState(false);
  const [commission, setCommission] = useState({
    value: '0,00',
    value_type: '',
  });

  const commissionValueTypeOptions = useMemo(
    () => [
      { label: 'Importo', value: 'fixed_amount' },
      { label: 'Percentuale', value: 'percentage' },
    ],
    [],
  );

  // Shared Notification
  const [hasSharedNotification, setHasSharedNotification] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [newTag, setNewTag] = useState('');
  const [tagError, setTagError] = useState(false);

  // Renewal
  const [renewal, setRenewal] = useState<Renewal>({
    automatic: false,
    to_be_confirmed: false,
  });

  // Pricing rules
  const [hasPricingRules, setHasPricingRules] = useState(false);
  const pricingRulesNames = [{ label: 'Prezzo minimo', value: 'minimum_price' }];
  const pricingRulesTypes = [
    { label: 'Importo', value: 'fixed_amount' },
    { label: 'Percentuale', value: 'percentage' },
  ];

  const [pricingRules, setPricingRules] = useState<PricingRule[]>([]);

  // Variants
  const [hasWarranties, setHasWarranties] = useState(false);
  const [variants, setVariants] = useState<Variant[]>([]);

  const variantWarrantiesModifierType = [
    { label: 'Import Fisso', value: 'fixed_price' },
    { label: 'Percentuale', value: 'percentage' },
    { label: 'Nessuno', value: 'none' },
  ];

  const [variantWarranties, setVariantWarranties] = useState<Warranty[]>([]);

  // Files
  const [files, setFiles] = useState<File[]>([]);
  const [openFileDialog, setOpenFileDialog] = useState(false);

  // Variant default state
  const [defaultState, setDefaultState] = useState({
    name: '',
    description: '',
    price: '',
    salePrice: '',
    groupPolicy: false,
    toBeQuoted: false,
    directEmission: false,
    bankTransfer: false,
    hasWarranties: false,
    variantWarranties: [],
    renewal: { automatic: false, to_be_confirmed: false },
    files: [
      {
        key: '',
        title: '',
      },
    ],
  });

  // Empty fields
  const [emptyFields, setEmptyFields] = useState({
    name: false,
    price: false,
    company: false,
    category: false,
    commission_value: false,
    api_identifier: false,
  });

  /**
   * Fetch product data
   */
  useEffect(() => {
    async function fetchProductVariants() {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/admin/products/${params.id}/variants`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('mb__access_token')}` },
          timeout: 10000,
        });
        const data = response.data;

        if (data.status === 'success') {
          setVariants(data.data);

          // Compare variants id with match.params.variantId and set states
          const matchingVariant = data.data.filter((variant: Variant) => variant._id === params.variantId)[0];
          if (matchingVariant) {
            setName(matchingVariant.name || '');
            setDescription(matchingVariant.description || '');
            setSelectedVariantType(matchingVariant.type || 'basic');
            setApiIdentifier(matchingVariant.api_id || '');
            setToBeQuoted(matchingVariant.to_be_quoted || false);
            setDirectEmission(matchingVariant.direct_emission || false);
            setBankTransfer(matchingVariant.bank_transfer || false);

            // Group policy
            setGroupPolicy(matchingVariant?.group_policy || false);
            setGroupPolicyBroker(matchingVariant?.group_policy_metadata?.broker || '');
            setGroupPolicyBranch(matchingVariant?.group_policy_metadata?.branch || '');
            setGroupPolicyNumber(matchingVariant?.group_policy_metadata?.number || '');
            setGroupPolicyInclusionNumber(matchingVariant?.group_policy_metadata?.inclusion_number_prefix || '');

            // Individual sale
            setIndividualSale(matchingVariant?.individual_sale || false);

            // Email templates
            // setQuoteEmailTemplate(matchingVariant?.email_templates[0]?.value || '');
            // setPolicyEmailTemplate(matchingVariant?.email_templates[1]?.value || '');
            // setRenewalEmailTemplate(matchingVariant?.email_templates[2]?.value || '');

            setQuoteEmailTemplate(matchingVariant.email_templates && matchingVariant.email_templates[0] ? matchingVariant.email_templates[0].value : '');
            setPolicyEmailTemplate(matchingVariant.email_templates && matchingVariant.email_templates[1] ? matchingVariant.email_templates[1].value : '');
            setRenewalEmailTemplate(matchingVariant.email_templates && matchingVariant.email_templates[2] ? matchingVariant.email_templates[2].value : '');

            // Company
            setCompany(matchingVariant.company || null);
            setCompanyInputValue(matchingVariant.company ? matchingVariant.company.name : '');
            setSelectedCompanyOptions(matchingVariant.company ? [matchingVariant.company._id] : []);

            // Quotation fields
            setQuotationFields(matchingVariant.quotation_fields ?? []);
            setQuotationOptions(matchingVariant.quotation_fields.map((field: QuotationField) => field.position) || []);
            setQuotation(matchingVariant.quotation || false);

            // PricingTable
            setHasPricingTable(!!matchingVariant.pricing_table);
            setPricingTable(
              matchingVariant.pricing_table ? matchingVariant.pricing_table : { row: '', row_labels: [], column: '', column_labels: [], values: [] },
            );
            setRows(matchingVariant.pricing_table ? matchingVariant.pricing_table.row : '');
            setColumns(matchingVariant.pricing_table ? matchingVariant.pricing_table.column : '');

            // Pricing rules
            setPricingRules(matchingVariant.pricing_rules ? matchingVariant.pricing_rules : []);
            setPricingRulesOptions(matchingVariant.pricing_rules ? matchingVariant.pricing_rules.map((rule: PricingRule) => rule.position) : []);
            setHasPricingRules(matchingVariant.pricing_rules ? matchingVariant.pricing_rules.length > 0 : false);

            // Convert price and salePrice from number to string with two decimal places and comma as decimalSeparator
            setPrice(matchingVariant.price ? matchingVariant.price.toLocaleString('it-IT', { minimumFractionDigits: 2 }) : '');
            setSalePrice(matchingVariant.sale_price ? matchingVariant.sale_price.toLocaleString('it-IT', { minimumFractionDigits: 2 }) : '');

            // setHasOptions(matchingVariant.has_options || false);
            setHasWarranties(matchingVariant.warranties && matchingVariant.warranties.length > 0 ? true : false);
            setWarranties(matchingVariant.warranties ? matchingVariant.warranties.map((warranty: Warranty) => String(warranty.position)) : []);
            setVariantWarranties(matchingVariant.warranties ? matchingVariant.warranties : []);

            // Commission
            setHasCommission(matchingVariant.commission ? true : false);
            setCommission({
              value: matchingVariant.commission ? matchingVariant.commission.value.toLocaleString('it-IT', { minimumFractionDigits: 2 }) : '0,00',
              value_type: matchingVariant.commission ? matchingVariant.commission.value_type : '',
            });

            // Renewal
            setRenewal(matchingVariant.renewal || { automatic: false, to_be_confirmed: false });

            // Shared Notification
            setHasSharedNotification(
              matchingVariant.shared_notification &&
                (matchingVariant.shared_notification.email_list.length > 0 || matchingVariant.shared_notification.template !== undefined)
                ? true
                : false,
            );
            setSelectedTags(
              matchingVariant.shared_notification && matchingVariant.shared_notification.email_list ? matchingVariant.shared_notification.email_list : [],
            );

            // Set default state
            setDefaultState({
              name: matchingVariant.name || '',
              description: matchingVariant.description || '',
              groupPolicy: matchingVariant.group_policy || false,
              toBeQuoted: matchingVariant.to_be_quoted || false,
              directEmission: matchingVariant.direct_emission || false,
              bankTransfer: matchingVariant.bank_transfer || false,
              price: matchingVariant.price ? matchingVariant.price.toLocaleString('it-IT', { minimumFractionDigits: 2 }) : '',
              salePrice: matchingVariant.sale_price ? matchingVariant.sale_price.toLocaleString('it-IT', { minimumFractionDigits: 2 }) : '',
              hasWarranties: false,
              // matchingVariant.has_options || false,
              variantWarranties: matchingVariant.warranties || [],
              renewal: matchingVariant.renewal || { automatic: false, to_be_confirmed: false },
              files: matchingVariant.files || [],
            });
          }

          setLoadError(false);
        } else {
          setLoadError(true);
        }
      } catch (err: any) {
        console.log(err);
        setLoadError(true);
      } finally {
        setIsLoading(false);
      }
    }
    async function fetchCompanies() {
      try {
        setCompanyLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/admin/companies`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('mb__access_token')}` },
          timeout: 10000,
        });
        const data = response.data;

        if (data.status === 'success') {
          const companies = data.data.map((company: Company) => {
            return {
              label: company.name,
              value: company._id,
              company: company,
            };
          });
          setCompanyOptions(companies);
          setDeselectedCompaniesOptions(companies);
        }
      } catch (err: any) {
        console.log(err);
      } finally {
        setCompanyLoading(false);
      }
    }
    fetchProductVariants().then(() => {
      fetchCompanies();
    });
  }, [update, params.id, user.token]);

  /**
   * Save data
   */
  const handleSave = useCallback(async () => {
    try {
      setButtonSpinning(true);

      // Check if fields are empty
      let errFlag = false;
      if (name === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, name: true }));
        errFlag = true;
      }

      if (price === '' && !quotation && quotationFields.length === 0) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, price: true }));
        errFlag = true;
      }

      if (hasCommission && commission.value === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, commission_value: true }));
        errFlag = true;
      }

      if (selectedVariantType === 'api' && apiIdentifier === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, api_identifier: true }));
        errFlag = true;
      }

      if (company === null) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, company: true }));
        errFlag = true;
      }

      if (errFlag) {
        setButtonSpinning(false);
        return;
      }

      // Create form data
      const formData = new FormData();

      // Files
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          formData.append('documents', files[i]);
        }
      }

      // Pricing rules
      if (hasPricingRules) {
        pricingRules.forEach((rule: PricingRule, index: number) => {
          formData.append(`pricing_rules[${index}][name]`, rule.name);
          formData.append(`pricing_rules[${index}][type]`, rule.type);
          formData.append(`pricing_rules[${index}][value]`, rule.value);
          formData.append(`pricing_rules[${index}][position]`, String(rule.position));
        });
      }

      // Quotation fields
      if (quotation && quotationFields.length > 0) {
        quotationFields.forEach((field: QuotationField, index: number) => {
          formData.append(`quotation_fields[${index}][name]`, field.name);
          formData.append(`quotation_fields[${index}][type]`, field.type);
          formData.append(`quotation_fields[${index}][position]`, String(field.position));
          formData.append(`quotation_fields[${index}][result_modifier_type]`, field.result_modifier_type);
          formData.append(`quotation_fields[${index}][blocking_condition]`, String(field.blocking_condition));

          if (field.result_modifier_value) formData.append(`quotation_fields[${index}][result_modifier_value]`, field.result_modifier_value);
          if (field.api_field_name) formData.append(`quotation_fields[${index}][api_field_name]`, field.api_field_name);
          if (field.values && field.values?.length > 0) {
            field.values.forEach((value: { label: string; value: string } | QuotationField, index2: number) => {
              // Used when type select is selected
              if (isLabelValuePair(value)) {
                formData.append(`quotation_fields[${index}][values][${index2}][label]`, value.label);
                formData.append(`quotation_fields[${index}][values][${index2}][value]`, value.value);
              } else {
                formData.append(`quotation_fields[${index}][values][${index2}][name]`, value.name);
                formData.append(`quotation_fields[${index}][values][${index2}][type]`, value.type);
                if (value.api_field_name) {
                  formData.append(`quotation_fields[${index}][values][${index2}][api_field_name]`, value.api_field_name);
                }
              }
            });
          }
        });

        // Add pricing table if it exists
        if (hasPricingTable && pricingTable) {
          formData.append('pricing_table[row]', rows);
          formData.append('pricing_table[row_labels]', JSON.stringify(pricingTable.row_labels));
          formData.append('pricing_table[column]', columns);
          formData.append('pricing_table[column_labels]', JSON.stringify(pricingTable.column_labels));
          formData.append('pricing_table[values]', JSON.stringify(pricingTable.values));
          formData.append('pricing_table[is_range]', JSON.stringify(pricingTable.is_range));
        }
      }

      // Variant warranties
      if (variantWarranties.length > 0) {
        variantWarranties.forEach((warranty: Warranty, index: number) => {
          formData.append(`warranties[${index}][name]`, warranty.name);
          formData.append(`warranties[${index}][included]`, warranty.included ? 'true' : 'false');
          formData.append(`warranties[${index}][result_modifier_type]`, warranty.result_modifier_type);
          if (warranty.result_modifier_value) formData.append(`warranties[${index}][result_modifier_value]`, warranty.result_modifier_value);
          formData.append(`warranties[${index}][value]`, warranty.value);
          formData.append(`warranties[${index}][position]`, String(warranty.position));
        });
      }
      formData.append('name', name);
      formData.append('description', description);
      formData.append('price', quotation ? '0' : price);
      formData.append('type', selectedVariantType);
      if (company?._id) {
        formData.append('company', company._id);
      }

      // Api identifier
      if (selectedVariantType === 'api') {
        formData.append('api_id', apiIdentifier);
      }

      formData.append('sale_price', quotation ? '0' : salePrice);
      formData.append('group_policy', String(groupPolicy));
      if (groupPolicy) {
        formData.append('group_policy_broker', groupPolicyBroker);
        formData.append('group_policy_branch', groupPolicyBranch);
        formData.append('group_policy_number', groupPolicyNumber);
        formData.append('group_policy_inclusion_number_prefix', groupPolicyInclusionNumber);
      }

      // Shared notification
      if (hasSharedNotification) {
        selectedTags.forEach((tag: string, index: number) => {
          formData.append(`shared_notification[email_list][${index}]`, tag);
        });
      }

      // Individual sale
      formData.append('individual_sale', String(individualSale));

      // Email templates
      formData.append(`email_templates[0][label]`, 'quote');
      formData.append(`email_templates[0][value]`, quoteEmailTemplate);
      formData.append(`email_templates[1][label]`, 'policy');
      formData.append(`email_templates[1][value]`, policyEmailTemplate);
      formData.append(`email_templates[2][label]`, 'renewal');
      formData.append(`email_templates[2][value]`, renewalEmailTemplate);

      formData.append('to_be_quoted', String(toBeQuoted));
      formData.append('direct_emission', String(directEmission));
      formData.append('bank_transfer', String(bankTransfer));
      formData.append('quotation', String(quotation));
      hasCommission && formData.append('commission', JSON.stringify(commission));

      // Renewal
      formData.append('renewal[automatic]', String(renewal.automatic));
      formData.append('renewal[to_be_confirmed]', String(renewal.to_be_confirmed));

      const response = await axios.put(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/admin/products/${params.id}/variants/${params.variantId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        // Clear files
        setFiles([]);

        setActive(true);
        setUpdate(!update);
        setIsDirty(false);
      } else {
        setSaveError(true);
      }
    } catch (error) {
      console.log(error);
      setSaveError(true);
    } finally {
      setButtonSpinning(false);
    }
  }, [
    update,
    name,
    description,
    company,
    selectedVariantType,
    apiIdentifier,
    price,
    salePrice,
    groupPolicy,
    groupPolicyBroker,
    groupPolicyBranch,
    groupPolicyNumber,
    groupPolicyInclusionNumber,
    hasSharedNotification,
    selectedTags,
    toBeQuoted,
    bankTransfer,
    directEmission,
    individualSale,
    quoteEmailTemplate,
    policyEmailTemplate,
    renewalEmailTemplate,
    variantWarranties,
    quotation,
    quotationFields,
    pricingTable,
    hasPricingRules,
    pricingRules,
    hasCommission,
    commission,
    renewal,
    files,
  ]);

  /**
   * Policy Handlers
   */
  const handleNameChange = useCallback(
    (e: string) => {
      // If emptyFields.name is true, set it to false
      if (emptyFields.name) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, name: false }));
      }
      setName(e);
      setIsDirty(true);
    },
    [emptyFields.name],
  );

  // Description
  const handleDescriptionChange = useCallback((e: string) => {
    setDescription(e);
    setIsDirty(true);
  }, []);

  // Variant type
  const handleVariantTypeChange = useCallback((e: any) => {
    setSelectedVariantType(e);
    setIsDirty(true);
  }, []);

  // Api identifier handler
  const handleApiIdentifierChange = useCallback(
    (e: string) => {
      // If emptyFields.api_identifier is true, set it to false
      if (emptyFields.api_identifier) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, api_identifier: false }));
      }

      setApiIdentifier(e);
      setIsDirty(true);
    },
    [apiIdentifier, emptyFields.api_identifier],
  );

  // Group policy
  const handleGroupPolicyChange = useCallback(() => {
    setGroupPolicy(!groupPolicy);
    setIsDirty(true);
  }, [groupPolicy]);

  // Group policy broker
  const handleGroupPolicyBrokerChange = useCallback((e: string) => {
    setGroupPolicyBroker(e);
    setIsDirty(true);
  }, []);

  // Group policy branch
  const handleGroupPolicyBranchChange = useCallback((e: string) => {
    setGroupPolicyBranch(e);
    setIsDirty(true);
  }, []);

  // Group policy number
  const handleGroupPolicyNumberChange = useCallback((e: string) => {
    setGroupPolicyNumber(e);
    setIsDirty(true);
  }, []);

  // Group policy inclusion number
  const handleGroupPolicyInclusionNumberChange = useCallback((e: string) => {
    setGroupPolicyInclusionNumber(e);
    setIsDirty(true);
  }, []);

  // To be quoted
  const handleToBeQuotedChange = useCallback(() => {
    setToBeQuoted(!toBeQuoted);
    setIsDirty(true);
  }, [toBeQuoted]);

  // Bank Transfer
  const handleBankTransferChange = useCallback(() => {
    setBankTransfer(!bankTransfer);
    setIsDirty(true);
  }, [bankTransfer]);

  // Direct emission
  const handleDirectEmissionChange = useCallback(() => {
    setDirectEmission(!directEmission);
    setIsDirty(true);
  }, [directEmission]);

  // Individual sale
  const handleIndividualSaleChange = useCallback(() => {
    setIndividualSale(!individualSale);
    setIsDirty(true);
  }, [individualSale]);

  // Quote email template
  const handleQuoteEmailTemplateChange = useCallback((e: any) => {
    setQuoteEmailTemplate(e);
    setIsDirty(true);
  }, []);

  // Policy email template
  const handlePolicyEmailTemplateChange = useCallback((e: any) => {
    setPolicyEmailTemplate(e);
    setIsDirty(true);
  }, []);

  // Renewal email template
  const handleRenewalEmailTemplateChange = useCallback((e: any) => {
    setRenewalEmailTemplate(e);
    setIsDirty(true);
  }, []);

  // Quotation
  const handleQuotationChange = useCallback(
    (e: any) => {
      setQuotation(e);
      setIsDirty(true);
    },
    [quotation],
  );

  // Is pricing range
  const handlePricingRangeChange = useCallback((e: any) => {
    setPricingTable((prevTable) => ({ ...prevTable, is_range: e }));
    setIsDirty(true);
  }, []);

  const handlePriceChange = useCallback(
    (e: any) => {
      // If emptyFields.price is true, set it to false
      if (emptyFields.price) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, price: false }));
      }

      // Check if the price has a dot and replace it with a comma
      if (e.includes('.')) {
        e = e.replace('.', ',');
      }

      // Check if the price has 2 or more commas and remove the last one
      if (e.split(',').length > 2) {
        e = e.replace(/,(?=[^,]*$)/, '');
      }

      // Check if the price has 3 or more numbers after the comma and remove the last one
      if (e.split(',')[1] && e.split(',')[1].length > 2) {
        e = e.replace(/.$/, '');
      }

      // Check if there are letters or special characters and remove them
      if (e.match(/[^0-9,]/g)) {
        e = e.replace(/[^0-9,]/g, '');
      }

      setPrice(e);
      setIsDirty(true);
    },
    [emptyFields.price],
  );

  const handleSalePriceChange = useCallback((e: any) => {
    // Check if the price has a dot and replace it with a comma
    if (e.includes('.')) {
      e = e.replace('.', ',');
    }

    // Check if the price has 2 or more commas and remove the last one
    if (e.split(',').length > 2) {
      e = e.replace(/,(?=[^,]*$)/, '');
    }

    // Check if the price has 3 or more numbers after the comma and remove the last one
    if (e.split(',')[1] && e.split(',')[1].length > 2) {
      e = e.replace(/.$/, '');
    }

    // Check if there are letters or special characters and remove them
    if (e.match(/[^0-9,]/g)) {
      e = e.replace(/[^0-9,]/g, '');
    }

    setSalePrice(e);
    setIsDirty(true);
  }, []);

  /** Pricing rules */
  const handleHasPricingRulesChange = useCallback(() => {
    setHasPricingRules(!hasPricingRules);
    setIsDirty(true);
  }, [hasPricingRules]);

  /** Commission */
  const handleHasCommissionChange = useCallback(() => {
    setHasCommission(!hasCommission);
    setIsDirty(true);
  }, [hasCommission]);

  const handleCommissionValueChange = useCallback(
    (e: any) => {
      // Check if the price has 2 or more commas and remove the last one
      if (e.split(',').length > 2) {
        e = e.replace(/,(?=[^,]*$)/, '');
      }

      // Check if there are letters or special characters and remove them
      if (e.match(/[^0-9,]/g)) {
        e = e.replace(/[^0-9,]/g, '');
      }

      // If commission type is percentage, check if the value is greater than 100
      if (commission.value_type === 'percentage' && parseFloat(e.replace(',', '.')) > 100) {
        e = '100';
      }

      setCommission({ ...commission, value: e });
      setIsDirty(true);
    },
    [commission],
  );

  const handleCommissionTypeChange = useCallback(
    (e: any) => {
      setCommission({ ...commission, value_type: e });
      setIsDirty(true);
    },
    [commission],
  );

  /** Shared notification */
  const handleHasSharedNotificationChange = useCallback(() => {
    setHasSharedNotification(!hasSharedNotification);
    setIsDirty(true);
  }, [hasSharedNotification]);

  const handleHasWarrantiesChange = useCallback(() => {
    setHasWarranties(!hasWarranties);
    setIsDirty(true);
  }, [hasWarranties]);

  /** Validate email */
  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };

  /**
   * Add warranty option
   */
  const handleAddWarrantyOption = useCallback(() => {
    setWarranties((warranties) => [...warranties, String(warranties.length + 1)]);
  }, []);

  /**
   * Remove warranty option
   */
  const handleRemoveWarranty = useCallback(
    (id: string) => {
      setWarranties((warranties) => warranties.filter((warranty) => warranty !== id));
      // Remove the warranty from the variantWarranties array if it exists
      setVariantWarranties((variantWarranties) => {
        const newVariantOptions = variantWarranties.filter((warranty) => String(warranty.position) !== id);
        return newVariantOptions;
      });
    },
    [setWarranties],
  );

  /**
   * Add variant option
   */
  const handleAddVariantWarranty = useCallback(
    (
      position: string,
      name: string,
      value: string,
      included: boolean,
      deductible: string,
      result_modifier_type: 'fixed_amount' | 'percentage' | 'none',
      result_modifier_value?: string,
    ) => {
      setVariantWarranties((variantWarranties) => [
        ...variantWarranties,
        {
          name: name,
          value: value,
          position: Number(position),
          result_modifier_type: result_modifier_type,
          included: included,
          deductible: deductible,
          result_modifier_value: result_modifier_value,
        },
      ]);
      setIsDirty(true);
    },
    [],
  );

  /**
   * Update variant option
   */
  const handleUpdateVariantWarranty = useCallback(
    (
      id: string,
      position: string,
      name: string,
      value: string,
      included: boolean,
      deductible: string,
      result_modifier_type: 'fixed_amount' | 'percentage' | 'none',
      result_modifier_value?: string,
    ) => {
      const tmp = variantWarranties;
      const index = tmp.findIndex((warranty: any) => String(warranty._id) === id);
      tmp[index] = {
        name: name,
        value: value,
        position: Number(position),
        result_modifier_type: result_modifier_type,
        included: included,
        deductible: deductible,
        result_modifier_value: result_modifier_value,
      };

      setVariantWarranties(tmp);
      setIsDirty(true);
    },
    [variantWarranties],
  );

  /**
   * Warranty markup
   */
  const warrantyMarkup = (
    <>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={quotationOptions} strategy={verticalListSortingStrategy}>
          {warranties.map((id, index) => (
            <SortableItemWarranty
              key={id}
              id={id}
              warrantyItem={variantWarranties[index]}
              options={variantWarrantiesModifierType}
              onDelete={handleRemoveWarranty}
              onSave={handleAddVariantWarranty}
              onUpdate={handleUpdateVariantWarranty}
            />
          ))}
        </SortableContext>
      </DndContext>
      <LegacyCard.Section>
        <Button plain removeUnderline icon={PlusMinor as any} onClick={handleAddWarrantyOption}>
          Aggiungi garanzia
        </Button>
      </LegacyCard.Section>
    </>
  );

  /**
   * Add quotation option
   */
  const handleAddQuotationOption = useCallback(() => {
    setQuotationOptions((options) => [...options, String(options.length + 1)]);
  }, []);

  /**
   * Remove quotation field
   */
  const handleRemoveQuotationField = useCallback(
    (id: string) => {
      setQuotationOptions((options) => options.filter((option) => option !== id));
      // Remove the option from the quotationFields array if it exists
      const tmp = quotationFields;
      const index = tmp.findIndex((option) => String(option.position) === id);
      tmp.splice(index, 1);
      setQuotationFields(tmp);
      setIsDirty(true);
    },
    [setQuotationOptions, quotationFields],
  );

  /**
   * Add quotation field
   */
  const handleAddQuotationField = useCallback(
    (
      position: string,
      name: string,
      type: 'select' | 'percentage' | 'number' | 'boolean' | 'text' | 'currency' | 'array' | 'date',
      result_modifier_type: 'fixed_amount' | 'percentage' | 'pricing_table' | 'none',
      // values?: Array<{ label: string; value: string }>,
      values?: string,
      result_modifier_value?: string,
      api_field_name?: string,
      blocking_condition?: string,
    ) => {
      // If values is not empty, convert it to a JSON array and check if each object has a label and a value
      let tmp: Array<{ label: string; value: string }> = [];
      if (values) {
        try {
          if (typeof values === 'string') {
            tmp = JSON.parse(values);
            tmp.forEach((value) => {
              if (!isLabelValuePair(value)) {
                throw new Error('Invalid values');
              }
            });
          } else if (Array.isArray(values)) {
            tmp = values;
          }
        } catch (err) {
          console.log(err);
          return;
        }
      }

      setQuotationFields((quotationFields) => [
        ...quotationFields,
        {
          name: name,
          type: type,
          values: tmp,
          position: Number(position),
          result_modifier_type: result_modifier_type,
          result_modifier_value: result_modifier_value,
          api_field_name: api_field_name,
          blocking_condition: blocking_condition,
        },
      ]);
      setIsDirty(true);
    },
    [],
  );

  // Define a type guard for { label: string; value: string; }
  function isLabelValuePair(value: any): value is { label: string; value: string } {
    return value && typeof value.label === 'string' && typeof value.value === 'string' && value.label !== '' && value.value !== '';
  }

  /**
   * Update quotation field
   */
  const handleUpdateQuotationField = useCallback(
    (
      _id: string,
      position: string,
      name: string,
      type: 'select' | 'percentage' | 'number' | 'boolean' | 'text' | 'currency' | 'array' | 'date',
      result_modifier_type: 'fixed_amount' | 'percentage' | 'pricing_table' | 'none',
      values?: string,
      result_modifier_value?: string,
      api_field_name?: string,
      blocking_condition?: string,
    ) => {
      const tmp = quotationFields;
      const index = tmp.findIndex((field: any) => field._id === _id);
      // If values is not empty, convert it to a JSON array and check if each object has a label and a value
      let tmpValues: Array<{ label: string; value: string }> | Array<QuotationField> = [];
      if (values) {
        try {
          if (typeof values === 'string') {
            tmpValues = JSON.parse(values);
            tmpValues.forEach((value) => {
              if (!isLabelValuePair(value)) {
                throw new Error('Invalid values');
              }
            });
          } else if (Array.isArray(values)) {
            tmpValues = values;
          }
        } catch (err) {
          console.log(err);
          return;
        }
      }
      tmp[index] = {
        name: name,
        position: Number(position),
        type: type,
        result_modifier_type: result_modifier_type,
        result_modifier_value: result_modifier_value,
        values: tmpValues,
        api_field_name: api_field_name,
        blocking_condition: blocking_condition,
      };

      setQuotationFields(tmp);
      setIsDirty(true);
    },
    [quotationFields],
  );

  /**
   * Quotation markup
   */
  const quotationMarkup = (
    <>
      <DndContext sensors={sensorsQuotation} collisionDetection={closestCenter} onDragEnd={handleDragEndQuotation}>
        <SortableContext items={quotationOptions} strategy={verticalListSortingStrategy}>
          {quotationOptions.map((id, index) => (
            <SortableItemQuotation
              key={id}
              id={id}
              quotationItem={quotationFields[index]}
              options={quotationFieldsType}
              onDelete={handleRemoveQuotationField}
              onSave={handleAddQuotationField}
              onUpdate={handleUpdateQuotationField}
              hasPricingTable={hasPricingTable}
              setHasPricingTable={setHasPricingTable}
            />
          ))}
        </SortableContext>
      </DndContext>
      <LegacyCard.Section>
        <Button plain removeUnderline icon={PlusMinor as any} onClick={handleAddQuotationOption}>
          Aggiungi campo quotazione
        </Button>
      </LegacyCard.Section>
    </>
  );

  /**
   * Add pricing rule option
   */
  const handleAddPricingRulesOption = useCallback(() => {
    setPricingRulesOptions((options) => [...options, String(options.length + 1)]);
  }, []);

  /**
   * Remove quotation field
   */
  const handleRemovePricingRule = useCallback(
    (id: string) => {
      setPricingRulesOptions((options) => options.filter((option) => option !== id));
      // Remove the option from the pricingRules array if it exists
      const tmp = pricingRules;
      const index = tmp.findIndex((option) => String(option.position) === id);
      tmp.splice(index, 1);
      setPricingRules(tmp);
      setIsDirty(true);
    },
    [setPricingRulesOptions, pricingRules],
  );

  /**
   * Add quotation field
   */
  const handleAddPricingRule = useCallback((position: string, name: 'minimum_price', type: 'fixed_amount' | 'percentage', value: string) => {
    setPricingRules((pricingRules) => [...pricingRules, { name: name, type: type, value: value, position: Number(position) }]);
    setIsDirty(true);
  }, []);

  /**
   * Update quotation field
   */
  const handleUpdatePricingRule = useCallback(
    (_id: string, position: string, name: 'minimum_price', type: 'fixed_amount' | 'percentage', value: string) => {
      const tmp = pricingRules;
      const index = tmp.findIndex((field: any) => field._id === _id);
      tmp[index] = { name: name, type: type, value: value, position: Number(position) };

      setPricingRules(tmp);
      setIsDirty(true);
    },
    [pricingRules],
  );

  /**
   * Pricing table
   */

  // State to manage the pricing table input fields
  // We will identify the input fields by their index from the table by the formula (number of columns * row index + column index))
  // Update the pricing table values
  const handleUpdatePricingTableValues = useCallback(
    (values: number[][]) => {
      const tmp = pricingTable;
      tmp.values = values;
      setPricingTable({ ...pricingTable, values: values });
      setIsDirty(true);
    },
    [pricingTable],
  );

  // Add Pricing Table
  const handleAddPricingTable = useCallback(() => {
    // Fetch the values from the quotationField array
    const rowTmp = quotationFields.find((field) => field.api_field_name === rows)?.result_modifier_value;
    const columnTmp = quotationFields.find((field) => field.api_field_name === columns)?.result_modifier_value;

    if (!rowTmp || !columnTmp) {
      return;
    }

    // convert the values to an array of strings
    const rowLabelsArray = rowTmp?.split(',').map((rowLabel) => rowLabel.trim());
    const columnLabelsArray = columnTmp?.split(',').map((columnLabel) => columnLabel.trim());

    // set the intial state of the table as a matrix of zeros, with dimensions equal to the number of rows and columns
    const valuesTmp: Array<Array<number>> = [];
    for (let i = 0; i < rowLabelsArray.length; i++) {
      valuesTmp.push([]);
      for (let j = 0; j < columnLabelsArray.length; j++) {
        valuesTmp[i].push(0);
      }
    }
    setPricingTable({ row: rows, row_labels: rowLabelsArray, column: columns, column_labels: columnLabelsArray, values: valuesTmp });

    // disable the creation of more tables
    setPricingTableExists(true);
    setIsDirty(true);
  }, [quotation, pricingTable, quotationFields, rows, columns]);

  // Update pricing table
  const handleUpdatePricingTable = useCallback(() => {
    const row = quotationFields.find((field) => field.api_field_name === rows)?.result_modifier_value;
    const column = quotationFields.find((field) => field.api_field_name === columns)?.result_modifier_value;

    if (!row || !column) {
      return;
    }

    const rowLabelsArray = row.split(',').map((rowLabel) => rowLabel.trim());
    const columnLabelsArray = column.split(',').map((columnLabel) => columnLabel.trim());

    // Create an empty matrix with the dimensions of the pricing table
    const updatedState: Array<Array<number>> = [];
    for (let i = 0; i < rowLabelsArray.length; i++) {
      updatedState.push([]);
      for (let j = 0; j < columnLabelsArray.length; j++) {
        updatedState[i].push(0);
      }
    }
    setPricingTable({ row: rows, row_labels: rowLabelsArray, column: columns, column_labels: columnLabelsArray, values: updatedState });
    setIsDirty(true);
  }, [quotation, pricingTable, quotationFields, rows, columns]);

  // Remove pricing table
  const handleRemovePricingTable = useCallback(() => {
    setPricingTableExists(false);
    setPricingTable({ row: '', row_labels: [], column: '', column_labels: [], values: [] });
    setRows('');
    setColumns('');
    setIsDirty(true);
  }, []);

  // UseEffect to update the pricing table when the rows or columns change
  useEffect(() => {
    if (hasPricingTable) {
      const rowLabelsTmp = pricingTable.row_labels.map((rowLabel, rowIndex) => {
        const tmpRows = [];
        tmpRows.push(rowLabel);
        pricingTable.column_labels.forEach((columnLabel, columnIndex) => {
          const currentInput = [rowIndex, columnIndex];
          const stringMatrix = JSON.stringify(currentInput);
          tmpRows.push(<EditableRows state={pricingTable.values} setState={handleUpdatePricingTableValues} type="number" selfPointer={stringMatrix} />);
        });
        return tmpRows;
      });

      const columnContents: Array<TColumnContent> = pricingTable.column_labels.map((columnLabel) => ({
        columnType: 'numeric',
        columnTitle: columnLabel,
      }));

      setColumnLabels([{ columnType: 'text', columnTitle: '' }, ...columnContents]);
      setRowsLabels(rowLabelsTmp);
    }
  }, [rows, columns, pricingTable, pricingTableExists, pricingTableExists]);

  /**
   * Pricing rules markup
   */
  const pricingRulesMarkup = (
    <>
      <DndContext sensors={sensorsPricingRules} collisionDetection={closestCenter} onDragEnd={handleDragEndPricingRules}>
        <SortableContext items={pricingRulesOptions} strategy={verticalListSortingStrategy}>
          {pricingRulesOptions.map((id, index) => (
            <SortableItemPricingRule
              key={id}
              id={id}
              pricingRuleItem={pricingRules[index]}
              nameOptions={pricingRulesNames}
              typeOptions={pricingRulesTypes}
              onDelete={handleRemovePricingRule}
              onSave={handleAddPricingRule}
              onUpdate={handleUpdatePricingRule}
            />
          ))}
        </SortableContext>
      </DndContext>
      <LegacyCard.Section>
        <Button plain removeUnderline icon={PlusMinor as any} onClick={handleAddPricingRulesOption}>
          Aggiungi regola
        </Button>
      </LegacyCard.Section>
    </>
  );

  /**
   * Drop zone
   */
  const handleDropZoneDrop = useCallback((_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
    setFiles((files) => [...files, ...acceptedFiles]);
    setIsDirty(true);
  }, []);

  const toggleOpenFileDialog = useCallback(() => {
    setOpenFileDialog((openFileDialog) => !openFileDialog);
  }, []);

  const filesInDb = (
    <div style={{ paddingTop: '1rem' }}>
      <LegacyStack vertical>
        {defaultState.files.map((file: any, index: number) => (
          <a href={`${process.env.REACT_APP_BLOB_DOCUMENTS_URL}${file.key}`} key={index} target="_blank" rel="noreferrer">
            <LegacyStack alignment="center" key={index}>
              <Thumbnail size="small" alt={file.title} source={NoteMinor as any} />
              <div>{file.title}</div>
            </LegacyStack>
          </a>
        ))}
      </LegacyStack>
    </div>
  );

  const fileUpload = !files.length && <DropZone.FileUpload actionHint="Accetta pdf" />;

  const uploadedFiles = files.length > 0 && (
    <div style={{ padding: '0' }}>
      <LegacyStack vertical>
        {files.map((file, index) => (
          <LegacyStack alignment="center" key={index}>
            <Thumbnail size="small" alt={file.name} source={NoteMinor as any} />
            <div>
              {file.name} <p>{file.size} bytes</p>
            </div>
          </LegacyStack>
        ))}
      </LegacyStack>
    </div>
  );

  const dropZoneMarkup = (
    <>
      <DropZone onDrop={handleDropZoneDrop} accept="application/pdf" onFileDialogClose={toggleOpenFileDialog} openFileDialog={openFileDialog}>
        {uploadedFiles}
        {fileUpload}
      </DropZone>
      {filesInDb}
    </>
  );

  /**
   * Renewal handler
   */
  const handleRenewalAutomaticChange = useCallback(
    (value: any) => {
      // If renewal.automatic is true, set it to false and also set renewal.to_be_confirmed to false
      if (renewal.automatic) {
        setRenewal({ automatic: false, to_be_confirmed: false });
        setIsDirty(true);
        return;
      }
      setRenewal({ ...renewal, automatic: value });
      setIsDirty(true);
    },
    [renewal],
  );

  const handleRenewalToBeConfirmedChange = useCallback(
    (value: any) => {
      setRenewal({ ...renewal, to_be_confirmed: value });
      setIsDirty(true);
    },
    [renewal],
  );

  /**
   * Company autocomplete
   */
  const updateCompanyText = useCallback(
    (value: any) => {
      setCompanyInputValue(value);

      if (!companyLoading) {
        setCompanyLoading(true);
      }

      setTimeout(() => {
        if (value === '') {
          setCompanyOptions(deselectedCompaniesOptions);
          setCompanyLoading(false);
          return;
        }
        const filterRegex = new RegExp(value, 'i');
        const resultOptions = deselectedCompaniesOptions.filter((option: any) => option.label.match(filterRegex));
        setCompanyOptions(resultOptions);
        setCompanyLoading(false);
      }, 300);
    },
    [deselectedCompaniesOptions, companyLoading],
  );

  const updateCompanySelection = useCallback(
    (selected: any) => {
      // Check if emptyFields is true
      if (emptyFields.company) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, company: false }));
      }

      const selectedCompany = selected.map((selectedItem: any) => {
        const matchedOption = companyOptions.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption;
      });
      setSelectedCompanyOptions(selected);
      setCompanyInputValue(selectedCompany[0].label);

      // Add company
      const company = selectedCompany[0].company;
      setCompany(company);
      setIsDirty(true);
    },
    [companyOptions],
  );

  const companyTextField = (
    <Autocomplete.TextField
      onChange={updateCompanyText}
      label="Compagnia"
      value={companyInputValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca compagnia"
      autoComplete="off"
      error={emptyFields.company && 'Per favore seleziona una compagnia'}
    />
  );

  const emptyCompanyState = (
    <React.Fragment>
      <div style={{ textAlign: 'center' }}>
        <TextContainer>Nessun cliente trovato</TextContainer>
      </div>
    </React.Fragment>
  );

  /**
   * Company markup
   *
   * Display autocomplete if company is not selected
   * When company is loaded, display company details
   */
  const companyMarkup =
    company === null ? (
      <LegacyStack vertical>
        <Autocomplete
          options={companyOptions}
          selected={selectedCompanyOptions}
          onSelect={updateCompanySelection}
          loading={companyLoading}
          textField={companyTextField}
          emptyState={emptyCompanyState}
        />
      </LegacyStack>
    ) : (
      <HorizontalStack blockAlign="center" gap="5">
        {company.logo && <Thumbnail size="medium" alt={company.logo.title} source={process.env.REACT_APP_BLOB_IMAGES_URL + company.logo.key} />}
        <Text as="span" fontWeight="semibold">
          {company.name}
        </Text>
        <div style={{ marginLeft: 'auto' }}>
          <Button
            icon={CancelSmallMinor as any}
            onClick={() => {
              setCompany(null);
              setSelectedCompanyOptions([]);
              setCompanyInputValue('');
            }}
            plain
          />
        </div>
      </HorizontalStack>
    );

  /**
   * Contextual Save Bar
   */
  const handleDiscard = useCallback(() => {
    setIsDirty(false);

    // Reset all the fields to the defaultState
    setName(defaultState.name);
    setDescription(defaultState.description);
    setPrice(defaultState.price);
    setSalePrice(defaultState.salePrice);
    setHasWarranties(defaultState.hasWarranties);
    setVariantWarranties(defaultState.variantWarranties);
  }, []);

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message={'Modifiche non salvate'}
      saveAction={{
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
      contextControl={contextControlMarkup}
    />
  ) : null;

  /**
   * Variant list markup
   */
  const variantListMarkup = (
    <ul className="variantList">
      {variants.map((variant, index) => (
        <li key={index} className="variantItem">
          <UnstyledLink
            url={variant._id !== params.variantId ? `/admin/products/${params.id}/variants/${variant._id}` : null}
            className={`variantItemStack ${variant._id === params.variantId && 'active'}`}
          >
            <LegacyStack>
              <LegacyStack.Item>
                <Icon source={FraudProtectMajor as any} />
              </LegacyStack.Item>
              <LegacyStack.Item>
                <span className="variantItemLink">{variant.name}</span>
              </LegacyStack.Item>
            </LegacyStack>
          </UnstyledLink>
        </li>
      ))}
    </ul>
  );

  /** Notifaction email removal */
  const removeTag = useCallback(
    (tag: string) => () => {
      setSelectedTags((previousTags) => previousTags.filter((previousTag) => previousTag !== tag));
      setIsDirty(true);
    },
    [],
  );

  /** Notification Tag Markup */
  const notificationTagMarkup = selectedTags.map((option) => (
    <Tag key={option} onRemove={removeTag(option)}>
      {option}
    </Tag>
  ));

  /** Add shared notification mail when button clicked based on newTag state */
  const addTag = () => {
    const tagError = !validateEmail(newTag);
    if (tagError) {
      setTagError(true);
      return;
    } else {
      setTagError(false);
      newTag !== '' && !selectedTags.includes(newTag) && setSelectedTags([...selectedTags, newTag]);
      setNewTag('');
    }
  };

  const handleNewTagOnChange = useCallback((value: any) => {
    setNewTag(value);
    setIsDirty(true);
  }, []);

  /**
   * Error markups & toast
   */
  const toastMarkup = active ? <Toast content="I dati sono stati aggiornati con successo." onDismiss={toggleActive} /> : null;

  const saveErrorMarkup = saveError && (
    <Layout.Section fullWidth>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  /**
   * Page markup
   */
  const actualPageMarkup = (
    <Page title="Modifica variante" backAction={{ content: 'Prodotto', url: `/admin/products/${params.id}` }}>
      <Layout>
        {/* Banner */}
        {saveErrorMarkup}

        <Layout.Section secondary>
          {/* Status */}
          <LegacyCard title="Varianti">
            <div className="variantListContainer">
              <Scrollable shadow>{variantListMarkup}</Scrollable>
            </div>
          </LegacyCard>
        </Layout.Section>

        <Layout.Section>
          {/* Name, description, to be quoted & global policy */}
          <LegacyCard>
            <LegacyCard.Section>
              <FormLayout>
                <TextField label="Nome" value={name} onChange={handleNameChange} autoComplete="off" error={emptyFields.name && 'Il nome è obbligatorio'} />
                <TextField label="Descrizione" value={description} onChange={handleDescriptionChange} autoComplete="off" />
                <Checkbox label="Preventivo da quotare manualmente" checked={toBeQuoted} onChange={handleToBeQuotedChange} />
                <Checkbox label="Prevede l'emissione diretta" checked={directEmission} onChange={handleDirectEmissionChange} />
              </FormLayout>
            </LegacyCard.Section>
            {/* Bank Transfer */}
            <LegacyCard.Section>
              <FormLayout>
                <Checkbox label="Da pagare tramite bonifico bancario" checked={bankTransfer} onChange={handleBankTransferChange} />
              </FormLayout>
            </LegacyCard.Section>
            {/* Individual sell  */}
            <LegacyCard.Section>
              <FormLayout>
                <Checkbox label="Può solo essere venduto individualmente" checked={individualSale} onChange={handleIndividualSaleChange} />
                {individualSale && (
                  <FormLayout.Group>
                    <TextField label="Template email preventivo" value={quoteEmailTemplate} onChange={handleQuoteEmailTemplateChange} autoComplete="off" />
                  </FormLayout.Group>
                )}
                {individualSale && (
                  <FormLayout.Group>
                    <TextField label="Template email polizza" value={policyEmailTemplate} onChange={handlePolicyEmailTemplateChange} autoComplete="off" />
                  </FormLayout.Group>
                )}
                {individualSale && (
                  <FormLayout.Group>
                    <TextField label="Template email rinnovo" value={renewalEmailTemplate} onChange={handleRenewalEmailTemplateChange} autoComplete="off" />
                  </FormLayout.Group>
                )}
              </FormLayout>
            </LegacyCard.Section>
            {/* Group policy */}
            <LegacyCard.Section>
              <FormLayout>
                <Checkbox label="È una polizza collettiva" checked={groupPolicy} onChange={handleGroupPolicyChange} />
                {groupPolicy && (
                  <FormLayout.Group>
                    <TextField label="Numero polizza" value={groupPolicyNumber} onChange={handleGroupPolicyNumberChange} autoComplete="off" />
                    <TextField
                      label="Prefisso numero inclusione"
                      value={groupPolicyInclusionNumber}
                      onChange={handleGroupPolicyInclusionNumberChange}
                      autoComplete="off"
                    />
                  </FormLayout.Group>
                )}
                {groupPolicy && (
                  <FormLayout.Group>
                    <TextField label="Broker" value={groupPolicyBroker} onChange={handleGroupPolicyBrokerChange} autoComplete="off" />
                    <TextField label="Ramo" value={groupPolicyBranch} onChange={handleGroupPolicyBranchChange} autoComplete="off" />
                  </FormLayout.Group>
                )}
              </FormLayout>
            </LegacyCard.Section>
            <LegacyCard.Section>
              {/* Company */}
              {companyMarkup}
            </LegacyCard.Section>
          </LegacyCard>

          {/* Renewal */}
          <LegacyCard title="Rinnovo">
            <LegacyCard.Section>
              <FormLayout>
                <Checkbox label="Il prodotto prevedere il rinnovo automatico" checked={renewal.automatic} onChange={handleRenewalAutomaticChange} />
                {renewal.automatic && (
                  <FormLayout.Group>
                    <Checkbox
                      label="Il rinnovo prevedere di essere confermato?"
                      checked={renewal.to_be_confirmed}
                      onChange={handleRenewalToBeConfirmedChange}
                    />
                  </FormLayout.Group>
                )}
              </FormLayout>
            </LegacyCard.Section>
          </LegacyCard>

          {/* Shared Notifications */}
          <LegacyCard title="Notifiche condivise">
            <LegacyCard.Section>
              <Checkbox
                label="Per questo prodotto sono previste notifiche condivise"
                checked={hasSharedNotification}
                onChange={handleHasSharedNotificationChange}
              />
            </LegacyCard.Section>
            {hasSharedNotification ? (
              <LegacyCard.Section>
                <FormLayout>
                  <LegacyStack spacing="tight">{notificationTagMarkup}</LegacyStack>
                  <TextField
                    label="Nuova mail"
                    value={newTag}
                    onChange={handleNewTagOnChange}
                    autoComplete="off"
                    error={tagError && 'Il formato della mail non è valido'}
                  />
                  <Button onClick={() => addTag()}>Aggiungi Mail</Button>
                </FormLayout>
              </LegacyCard.Section>
            ) : null}
          </LegacyCard>

          {/* Variant type */}
          <LegacyCard sectioned title="Tipologia di variante">
            <FormLayout>
              <FormLayout.Group>
                <Select label="Tipologia di variante" options={variantTypeOptions} onChange={handleVariantTypeChange} value={selectedVariantType} />
                {selectedVariantType === 'api' && (
                  <TextField
                    label="Idenficatore api"
                    value={apiIdentifier}
                    onChange={handleApiIdentifierChange}
                    autoComplete="off"
                    error={emptyFields.api_identifier && "Il nome dell'identificatore api è obbligatorio"}
                  />
                )}
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>

          {/* Pricing */}
          <LegacyCard title="Prezzi">
            <LegacyCard.Section>
              <Checkbox label="Questo prodotto permette una quotazione" checked={quotation} onChange={handleQuotationChange} />
            </LegacyCard.Section>
            {!quotation ? (
              <LegacyCard.Section>
                <FormLayout>
                  <TextField
                    label="Prezzo"
                    type="currency"
                    placeholder="0,00"
                    value={price}
                    onChange={handlePriceChange}
                    autoComplete="off"
                    suffix="€"
                    error={emptyFields.price && 'Il prezzo è obbligatorio'}
                  />
                  <TextField
                    label="Prezzo promozionale"
                    type="currency"
                    placeholder="0,00"
                    value={salePrice}
                    onChange={handleSalePriceChange}
                    autoComplete="off"
                    suffix={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ marginRight: '5px' }}>€</p>
                        <Tooltip content="Inserisci qui il prezzo promozionale che vuoi mostrare al cliente">
                          <Icon source={QuestionMarkMajor as any} />
                        </Tooltip>
                      </div>
                    }
                  />
                </FormLayout>
              </LegacyCard.Section>
            ) : (
              quotationMarkup
            )}
          </LegacyCard>

          {/* Pricing Table */}
          {hasPricingTable && (
            <LegacyCard
              title="Tabella dei prezzi"
              actions={[
                {
                  content: 'Elimina tabella',
                  onAction: handleRemovePricingTable,
                },
              ]}
            >
              <LegacyCard.Section>
                <FormLayout>
                  <Checkbox label="Il prezzo va calcolato come range" checked={pricingTable.is_range} onChange={handlePricingRangeChange} />
                  <TextField label="Campo righe" type="text" value={rows} onChange={handleRowsChange} autoComplete="off" />
                  <TextField label="Campo colonne" type="text" value={columns} onChange={handleColumnsChange} autoComplete="off" />
                  <div className="pricingTableButtons">
                    <Button onClick={handleAddPricingTable} disabled={pricingTableExists}>
                      Fatto
                    </Button>
                    <Button onClick={handleUpdatePricingTable}>Aggiorna</Button>
                  </div>
                </FormLayout>
              </LegacyCard.Section>
              {columnLabels && rowLabels && <EditableTable columnContents={columnLabels} rows={rowLabels} />}
            </LegacyCard>
          )}

          {/* Pricing Rules */}
          {quotation && (
            <LegacyCard title="Regole di prezzo">
              <LegacyCard.Section>
                <Checkbox
                  label="Questo prodotto prevede delle regole da applicare alla quotazione"
                  checked={hasPricingRules}
                  onChange={handleHasPricingRulesChange}
                />
              </LegacyCard.Section>
              {hasPricingRules ? pricingRulesMarkup : null}
            </LegacyCard>
          )}

          {/* Commission */}
          <LegacyCard title="Provvigioni">
            <LegacyCard.Section>
              <Checkbox label="Questo prodotto prevede delle commissioni" checked={hasCommission} onChange={handleHasCommissionChange} />
            </LegacyCard.Section>
            {hasCommission ? (
              <LegacyCard.Section>
                <FormLayout>
                  <FormLayout.Group condensed>
                    <Select
                      label="Tipo di provvigione"
                      options={commissionValueTypeOptions}
                      onChange={handleCommissionTypeChange}
                      value={commission.value_type}
                    />
                    <TextField
                      label="Prezzo"
                      type="currency"
                      placeholder="0,00"
                      value={commission.value}
                      onChange={handleCommissionValueChange}
                      autoComplete="off"
                      suffix={commission.value_type === 'fixed_amount' ? '€' : '%'}
                      min={0}
                      max={commission.value_type !== 'fixed_amount' ? 100 : undefined}
                      error={emptyFields.commission_value && 'Il prezzo è obbligatorio'}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </LegacyCard.Section>
            ) : null}
          </LegacyCard>

          {/* Warranties */}
          <LegacyCard title="Garanzie">
            <LegacyCard.Section>
              <Checkbox label="Questo prodotto ha garanzie" checked={hasWarranties} onChange={handleHasWarrantiesChange} />
            </LegacyCard.Section>
            {hasWarranties && warrantyMarkup}
          </LegacyCard>

          {/* Files */}
          <LegacyCard
            title="Documenti"
            actions={[
              {
                content: 'Carica file',
                onAction: toggleOpenFileDialog,
              },
            ]}
          >
            <LegacyCard.Section>
              <FormLayout>{dropZoneMarkup}</FormLayout>
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>

        <Layout.Section />
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  // ---- Error ----
  const errorPageMarkup = (
    <Page>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <EmptyState
              heading="Nessun prodotto presente a questo indirizzo"
              image="https://cdn.shopify.com/shopifycloud/web/assets/v1/08f1b23a19257042c52beca099d900b0.svg"
            >
              <p>Controlla l'URL e riprova oppure usa la barra di ricerca per trovare ciò di cui hai bisogno.</p>
            </EmptyState>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : loadError ? errorPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {contextualSaveBarMarkup}
      {pageMarkup}
      {toastMarkup}
    </Frame>
  );
}
