import React, { useCallback, useEffect, useRef, useState } from 'react';
import { saveAs } from 'file-saver';

import {
  Avatar,
  Button,
  LegacyCard,
  EmptyState,
  DropZone,
  OptionList,
  Modal,
  FormLayout,
  TextField,
  Frame,
  Labelled,
  Layout,
  Loading,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  LegacyStack,
  TextContainer,
  Text,
  Select,
  Banner,
  Toast,
  Badge,
  Link,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup, NewCustomerPolicy } from '../../../components';

import './CustomerDetails.scss';
import axios, { AxiosError } from 'axios';
import { Customer, CustomerFileTypes, Order } from '../../../types';
import { useUser } from '../../../utils/PrivateRoute';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { getLanguageLabel, parseDateLabel, renderCustomerStatusBadge } from '../../../utils/Common';

type FileOption = {
  label: string;
  value: any;
};

export function CustomerDetails() {
  const params = useParams();
  const { user } = useUser();
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [missingUserError, setMissingUserError] = useState(false);
  const [customerOrdersError, setCustomerOrdersError] = useState(false);

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: boolean) => {
    setMobileNavigationActive(!data);
  };

  const [fileUploadActive, setFileUploadActive] = useState(false);
  const toggleFileUploadActive = useCallback(() => setFileUploadActive((fileUploadActive) => !fileUploadActive), []);

  const [fileDeletedActive, setFileDeletedActive] = useState(false);
  const toggleFileDeletedActive = useCallback(() => setFileDeletedActive((fileDeletedActive) => !fileDeletedActive), []);

  const [newPolicyOrQuoteSuccess, setNewPolicyOrQuoteSuccess] = useState(false);
  const toggleSuccess = useCallback(() => setNewPolicyOrQuoteSuccess((newPolicyOrQuoteSuccess) => !newPolicyOrQuoteSuccess), []);

  // Check if file added is policy or quote
  const [isPolicy, setIsPolicy] = useState(false);
  const toggleIsPolicy = useCallback(() => setIsPolicy((isPolicy) => !isPolicy), []);

  const [modalAddressActive, setModalAddressActive] = useState(false);

  const handleModalAddressChange = useCallback(() => {
    setModalAddressActive(!modalAddressActive);
  }, [modalAddressActive]);

  // Delete customer
  const [deleted, setDeleted] = useState(false);
  const [deleteButtonSpinning, setDeleteButtonSpinning] = useState(false);
  const [modalDeleteCustomerActive, setModalDeleteCustomerActive] = useState(false);
  const handleDeleteCustomerModalChange = useCallback(() => {
    setModalDeleteCustomerActive(!modalDeleteCustomerActive);
  }, [modalDeleteCustomerActive]);

  // Next or prev url
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [update, setUpdate] = useState(false);

  const loadingMarkup = isLoading ? <Loading /> : null;

  /**
   * Customer details
   */
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [job, setJob] = useState('');

  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [fiscalCode, setFiscalCode] = useState('');
  const [phone, setPhone] = useState('');

  const [line, setLine] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('IT'); // Value is country.code
  const [province, setProvince] = useState('');

  const [countryOptions, setCountryOptions] = useState<{ label: string; value: string }[]>([]); // Used to load array of countries

  // Quotes and policies
  const [policies, setPolicies] = useState<Order[]>([]);
  const [quotes, setQuotes] = useState<Order[]>([]);

  // Errors
  const [zipError, setZipError] = useState(false);

  /**
   * File Upload - Documenti
   */
  const [files, setFiles] = useState<string[]>([]);
  const [fileID, setFileID] = useState<string[]>([]);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [txtFileUploadValue, setTxtFileUploadValue] = useState('');
  const [tmpFileUploadValue, setTmpFileUploadValue] = useState<any>();
  const [selectedFileUpload, setSelectedFileUpload] = useState<string[]>([]);
  const [optionsFileUpload, setOptionsFileUpload] = useState<FileOption[]>([]);
  const [activeFileUpload, setActiveFileUpload] = useState(false);
  const [isAdderFileUpload, setIsAdderFileUpload] = useState(false);
  const [selectedType, setSelectedType] = useState<string>(CustomerFileTypes.identity);

  const typeOptions = [
    { label: 'Documento di identità', value: CustomerFileTypes.identity },
    { label: 'Passaporto', value: CustomerFileTypes.passport },
    { label: 'Patente', value: CustomerFileTypes.driver_license },
    { label: 'Altro', value: CustomerFileTypes.other },
  ];

  /**
   * Data fetching
   */
  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/customers/${params.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          const { customer } = data.data;
          setCustomer(customer);
          setJob(customer.job ? customer.job.label : '');

          // If the customer has not a user, set error
          if (!customer.user) {
            setMissingUserError(true);
          }

          setName(customer.user?.name ?? '');
          setLastname(customer.user?.lastname ?? '');
          setPhone(customer.user?.phone ?? '');
          setFiscalCode(customer.fiscal_code ?? '');

          // If customer has an address, set it
          if (customer.address) {
            setLine(customer.address.line);
            setCity(customer.address.city);
            setZip(customer.address.zip);
            setCountry(customer.address.country);
            setProvince(customer.address.province);
          }

          // Prev and next url
          setPrevUrl(response.data.prevUrl);
          setNextUrl(response.data.nextUrl);

          // Files
          const fileArray = [];
          const fileNamesArray = [];
          const fileIdsArray = [];
          const fileOptions = [];
          const fileTypes = [];

          for (const item of customer.files) {
            fileArray.push('Document');
            fileNamesArray.push(item.title);
            fileIdsArray.push(item._id);
            const fileName = item.title.split('__')[1];
            fileOptions.push({
              label: `${fileName} - ${item.file_type}`,
              value: item.key,
            });
            fileTypes.push(item.file_type);
          }
          setFiles(fileArray);
          setFileNames(fileNamesArray);
          setFileID(fileIdsArray);
          setOptionsFileUpload(fileOptions);

          return customer.user?._id ?? null;
        } else {
          setError(true);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
        const status = axiosError.response?.status || 500;
        const data: any = axiosError.response?.data;

        setError(true);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchCountries = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL ?? '/api'}/countries`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setCountryOptions(data.data);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
        const status = axiosError.response?.status || 500;
        const data: any = axiosError.response?.data;
      } finally {
        setIsLoading(false);
      }
    };
    const fetchUserOrders = async (user_id: string | null) => {
      try {
        if (!user_id) return;

        setIsLoading(true);

        const response = await axios.get(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/orders/user/${user_id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setPolicies(data.data.policies);
          setQuotes(data.data.quotes);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
        const status = axiosError.response?.status || 500;
        const data: any = axiosError.response?.data;
      } finally {
        setIsLoading(false);
      }
    };
    fetchCustomer()
      .then((user_id) => fetchUserOrders(user_id))
      .then(() => fetchCountries());
  }, [update, params.id]);

  /**
   * Update
   */
  const [modalActive, setModalActive] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [active, setActive] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const handleModalChange = useCallback(() => {
    setModalActive(!modalActive);
  }, [modalActive]);

  const handleNameChange = useCallback((e: string) => {
    setName(e);
    setIsDirty(true);
  }, []);

  const handleLastnameChange = useCallback((e: string) => {
    // Do not consent numbers
    if (!e.match(/^[0-9]*$/)) {
      setLastname(e);
      setIsDirty(true);
    }
  }, []);

  const handleFiscalCodeChange = useCallback((e: string) => {
    setFiscalCode(e);
    setIsDirty(true);
  }, []);

  const handlePhoneChange = useCallback((e: string) => {
    // Allow only numbers
    if (e.match(/^[0-9]*$/)) {
      setPhone(e);
      setIsDirty(true);
    }
  }, []);

  // Country
  const handleCountryChange = useCallback((e: any) => {
    setCountry(e);
  }, []);

  const handleLineChange = useCallback((e: string) => {
    setLine(e);
  }, []);

  const handleCityChange = useCallback((e: string) => {
    setCity(e);
  }, []);

  const handleZipChange = useCallback(
    (e: string) => {
      if (zipError) {
        setZipError(false);
      }

      // Allow only numbers
      if (e.match(/^[0-9]*$/)) {
        setZip(e);
      }

      // Allow only 5 numbers
      if (e.length > 5) {
        setZip(e.substring(0, 5));
      }
    },
    [zipError],
  );

  const handleClose = useCallback(() => {
    handleModalChange();
    setName('');
  }, [handleModalChange]);

  /**
   * Update customer
   */
  const handleSave = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const response = await axios.put(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/customers/${params.id}`,
        {
          name: name,
          lastname: lastname,
          fiscal_code: fiscalCode,
          phone: phone,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        setActive(true);
        setUpdate(!update);
        setIsDirty(false);
      } else {
        handleClose();
        setSaveError(true);
      }
    } catch (error) {
      console.log(error);
      handleClose();
      setSaveError(true);
    } finally {
      setButtonSpinning(false);
    }
    handleClose();
  }, [handleClose, params.id, name, lastname, fiscalCode, phone, update]);

  /**
   * Add customer address
   */
  const handleAddAddress = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL ?? '/api'}/admin/customers/${params.id}/address`,
        {
          country: country,
          city: city,
          line: line,
          zip: zip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        setActive(true);
        setUpdate(!update);
      } else {
        setSaveError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      const status = axiosError.response?.status || 500;
      const data: any = axiosError.response?.data;

      setSaveError(true);
    } finally {
      setButtonSpinning(false);
      handleModalAddressChange();
    }
  }, [handleModalAddressChange, params.id, country, line, city, zip, update]);

  /**
   * Delete customer
   */
  const handleDeleteCustomer = useCallback(async () => {
    try {
      setDeleteButtonSpinning(true);
      const response = await axios.delete(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/customers/${params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const data = response.data;

      if (data.status === 'success') {
        setDeleted(true);
        // Redirect to customers after 3 seconds
        setTimeout(() => {
          navigate('/customers');
        }, 3000);
      } else {
        setSaveError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      const status = axiosError.response?.status || 500;
      const data: any = axiosError.response?.data;

      if (status === 409) {
        setCustomerOrdersError(true);
      } else {
        setSaveError(true);
      }
    } finally {
      setDeleteButtonSpinning(false);
      handleDeleteCustomerModalChange();
    }
  }, [handleDeleteCustomerModalChange, params.id]);

  /**
   * File Uploading Handler
   */
  const toggleModalFileUpload = useCallback(() => setActiveFileUpload((activeFileUpload: boolean) => !activeFileUpload), []);
  const toggleIsAdderFileUpload = useCallback((e: boolean) => {
    setIsAdderFileUpload((isAdderFileUpload) => {
      isAdderFileUpload = e;
      return isAdderFileUpload;
    });
  }, []);

  const handleFileUploadAdd = useCallback(
    (e: any, fn: string, type: any) => {
      if (e === '' || e === undefined || fn === '' || fn === undefined || type === undefined || type === '') return;

      setFiles((files) => {
        const newFiles = files;
        //@ts-ignore
        newFiles.push(e);
        return newFiles;
      });

      setFileNames((fileNames) => {
        const newFileNames = fileNames;
        //@ts-ignore
        newFileNames.push(fn);
        return newFileNames;
      });

      setOptionsFileUpload((optionsFileUpload) => {
        const newOptions = optionsFileUpload;
        //@ts-ignore
        newOptions.push({ label: `${fn.split('__')[1]} - ${type}`, value: fn });
        return newOptions;
      });

      const addFile = async () => {
        try {
          const fd = new FormData();
          fd.append('document', e);
          fd.append('type', type);

          const response = await axios.post(
            (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/customers/${params.id}/upload`,
            fd,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
              },
            },
          );

          const data = response.data;
          if (data.status === 'success') {
            setFileUploadActive(true);
          }
        } catch (error) {
          console.log(error);
          setError(true);
        }
      };
      addFile();
    },
    [params.id, typeOptions],
  );

  const handleConfirmFileUpload = useCallback(() => {
    if (isAdderFileUpload) {
      handleFileUploadAdd(tmpFileUploadValue, txtFileUploadValue, selectedType);
    }
    // else {
    //   handleFileUploadModify(tmpFileUploadValue, txtFileUploadValue, selectedFileUpload[0]);
    // }

    toggleModalFileUpload();
  }, [isAdderFileUpload, toggleModalFileUpload, handleFileUploadAdd, tmpFileUploadValue, txtFileUploadValue, selectedType, selectedFileUpload]);

  const handleTmpFileUploadValue = useCallback((e: any) => {
    setTmpFileUploadValue(e);
  }, []);

  const handleAddFileUpload = useCallback(
    (_dropFiles: any, acceptedFiles: any, _rejectedFiles: any) => {
      toggleModalFileUpload();
      toggleIsAdderFileUpload(true);
      const blob = acceptedFiles[0];
      const file = new File([blob], params.id + '__' + acceptedFiles[0].name);
      setTxtFileUploadValue(file.name);
      // @ts-ignore
      setTmpFileUploadValue((tmpFileUploadValue) => file);
    },
    [toggleModalFileUpload, toggleIsAdderFileUpload, params.id],
  );

  const handleSelectionFileUpload = useCallback((e: any) => {
    setSelectedFileUpload(e);
  }, []);

  /**
   * Delete file
   */
  const handleDelFileUpload = useCallback(() => {
    // @ts-ignore
    if (selectedFileUpload[0] === '' || selectedFileUpload[0] === undefined) return;

    const modFileName = selectedFileUpload[0];
    // @ts-ignore
    const modFile = files[fileNames.indexOf(selectedFileUpload[0])];
    // @ts-ignore
    const modFileID = fileID[fileNames.indexOf(selectedFileUpload[0])];

    const delFile = async () => {
      try {
        const fileKey = selectedFileUpload[0];

        await axios.delete((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/customers/${params.id}/files/${fileKey}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });

        setFileDeletedActive(true);
      } catch (error) {
        console.log(error);
        setError(true);
      }
    };
    delFile();

    setFiles((files) => {
      //@ts-ignore
      const indexOfDeletion = files.indexOf(modFile);
      files.splice(indexOfDeletion, 1);
      return files;
    });

    setFileNames((fileNames) => {
      //@ts-ignore
      const indexOfDeletion = fileNames.indexOf(modFileName);
      fileNames.splice(indexOfDeletion, 1);
      return fileNames;
    });

    setOptionsFileUpload((optionsFileUpload) => {
      //@ts-ignore
      const indexOfDeletion = optionsFileUpload.findIndex((op) => op.value === selectedFileUpload[0]);
      optionsFileUpload.splice(indexOfDeletion, 1);
      return optionsFileUpload;
    });

    setFileID((fileID) => {
      //@ts-ignore
      const indexOfDeletion = fileID.indexOf(modFileID);
      fileID.splice(indexOfDeletion, 1);
      return fileID;
    });

    handleSelectionFileUpload(['']);
  }, [selectedFileUpload, files, fileNames, fileID, handleSelectionFileUpload, params.id, user.id]);

  /**
   * Download single file
   */
  const handleOpenFile = useCallback(async () => {
    try {
      const fileKey = selectedFileUpload[0];

      const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/customers/${params.id}/files/${fileKey}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const response = await data.blob();

      // @ts-ignore
      saveAs(response, selectedFileUpload[0]);
    } catch (error) {
      console.log(error);
      setError(true);
    }
  }, [selectedFileUpload, user.id]);

  const fileUpload = !tmpFileUploadValue && <DropZone.FileUpload />;
  const uploadedFile = tmpFileUploadValue && (
    <LegacyStack>
      <div>
        {/*
        // @ts-ignore */}
        {tmpFileUploadValue.name.split('__')[1]}{' '}
        <Text variant="bodySm" as="p">
          {tmpFileUploadValue.size} bytes
        </Text>
      </div>
    </LegacyStack>
  );

  const toastPolicyOrQuoteMarkup = newPolicyOrQuoteSuccess ? (
    <Toast content={`${isPolicy ? 'La polizza è stata aggiunta correttamente!' : 'Il preventivo è stato aggiunto correttamente!'}`} onDismiss={toggleSuccess} />
  ) : null;

  const fileUploadJSX = (
    <div>
      <LegacyStack vertical>
        <DropZone allowMultiple={false} onDrop={handleAddFileUpload} disabled={missingUserError}>
          <DropZone.FileUpload />
        </DropZone>
        <LegacyStack distribution={'center'}>
          {selectedFileUpload[0] && <Button onClick={handleOpenFile}>Scarica</Button>}
          {/*selectedFileUpload[0] && <Button onClick={handleModFileUpload}>Modifica</Button>*/}
          {selectedFileUpload[0] && <Button onClick={handleDelFileUpload}>Elimina</Button>}
        </LegacyStack>
        {/*
        // @ts-ignore */}
        <OptionList onChange={handleSelectionFileUpload} options={optionsFileUpload} selected={selectedFileUpload} />
      </LegacyStack>

      <div>
        <Modal
          open={activeFileUpload}
          onClose={toggleModalFileUpload}
          title={isAdderFileUpload ? 'Conferma Aggiunta' : 'Conferma Modifica'}
          secondaryActions={[
            {
              content: 'Chiudi',
              onAction: toggleModalFileUpload,
            },
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <FormLayout.Group>
                <DropZone onDrop={handleTmpFileUploadValue}>
                  {uploadedFile}
                  {fileUpload}
                </DropZone>
              </FormLayout.Group>
              <FormLayout.Group>
                <Select label="Tipologia di documento" options={typeOptions} onChange={(e) => setSelectedType(e)} value={selectedType} />
              </FormLayout.Group>
              <FormLayout.Group>
                <Button primary onClick={handleConfirmFileUpload}>
                  {isAdderFileUpload ? 'Conferma Aggiunta' : 'Conferma Modifica'}
                </Button>
              </FormLayout.Group>
            </FormLayout>
          </Modal.Section>
        </Modal>
      </div>
    </div>
  );

  /**
   * New quote or policy
   */
  const [modalQuoteActive, setModalQuoteActive] = useState(false);
  const handleModalQuoteChange = useCallback(() => {
    setModalQuoteActive(!modalQuoteActive);
  }, [modalQuoteActive]);

  /**
   * Modals markup
   */
  const modalMarkup = modalActive && (
    <Modal
      open={modalActive}
      onClose={handleClose}
      title="Modifica cliente"
      primaryAction={{
        content: 'Salva',
        onAction: handleSave,
        disabled: !isDirty,
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Annulla',
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <TextField autoComplete="on" type="text" label="Nome" value={name} onChange={handleNameChange} />
            {customer?.type !== 'company' && <TextField autoComplete="on" type="text" label="Cognome" value={lastname} onChange={handleLastnameChange} />}
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField autoComplete="on" type="text" label="Codice Fiscale" value={fiscalCode} onChange={handleFiscalCodeChange} />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField autoComplete="on" type="tel" label="Numero di telefono" value={phone} onChange={handlePhoneChange} />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  const modalAddressMarkup = modalAddressActive && (
    <Modal
      open={modalAddressActive}
      onClose={handleModalAddressChange}
      title={customer?.address ? 'Modifica Indirizzo' : 'Aggiungi indirizzo'}
      primaryAction={{
        content: customer?.address ? 'Modifica' : 'Aggiungi',
        onAction: handleAddAddress,
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Cancella',
          onAction: handleModalAddressChange,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <Select label="Stato" options={countryOptions} onChange={handleCountryChange} value={country} />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField autoComplete="on" type="text" label="Indirizzo" value={line} onChange={handleLineChange} />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField autoComplete="on" type="text" label="Città" value={city} onChange={handleCityChange} />
            <TextField autoComplete="on" type="text" label="CAP" value={zip} onChange={handleZipChange} error={zipError && 'Controllare il CAP'} />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  /**
   * Delete customer modal
   */
  const deleteCustomerMarkup = modalDeleteCustomerActive && (
    <Modal
      open={modalDeleteCustomerActive}
      onClose={handleDeleteCustomerModalChange}
      title="Elimina cliente"
      primaryAction={{
        content: 'Elimina',
        onAction: handleDeleteCustomer,
        destructive: true,
        loading: deleteButtonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Annulla',
          onAction: handleDeleteCustomerModalChange,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          Sei sicuro di voler eliminare il cliente <strong>{customer?.user?.name}</strong>?
        </TextContainer>
      </Modal.Section>
    </Modal>
  );

  /**
   * Policies markup
   */
  const policiesList = [];
  for (const item of policies) {
    const classNames = dayjs(item.date_end) < dayjs() ? 'polizzeSectionBlock error' : 'polizzeSectionBlock';
    policiesList.push(
      <div key={item._id} className={classNames}>
        <Link removeUnderline url={`/policies/${item._id}`}>
          <div>
            {item.date_end ? (
              <div className="polizzeSectionSubheading">
                <Text as="p" variant="headingSm">
                  {parseDateLabel(new Date(item.date_end))}
                </Text>
              </div>
            ) : (
              <div className="polizzeSectionSubheading">
                <Text as="p" variant="headingSm">
                  <Badge>Scadenza non impostata</Badge>
                </Text>
              </div>
            )}
            <ul className="polizzeSectionUl">
              <li className="polizzeSectionLi">
                <div className="polizzeSectionMargin">
                  <div className="polizzeSectionText">
                    <div className="polizzeSectionPoint"></div>
                    <p className="polizzeSectionP">{item.line_items[0].name}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </Link>
      </div>,
    );
  }

  const policiesMarkup = (
    <>
      {/** Polizze */}
      <div className="polizzeSectionHeading">
        <Text variant="headingMd" as="h2">
          Polizze
        </Text>
      </div>
      <div>
        <div className="polizzeSectionInitial">
          <div className="polizzeSectionAvatar">
            <Avatar customer={false} size="medium" initials={'PO'} name={'_id'} />
          </div>
        </div>
        <div>{policiesList}</div>
      </div>
    </>
  );

  /**
   * Quotes markup
   */
  const quotesList = [];
  for (const item of quotes) {
    quotesList.push(
      <div key={item._id} className="polizzeSectionBlock">
        <Link removeUnderline url={`/quotes/${item._id}`}>
          <div>
            {item.date_end && (
              <div className="polizzeSectionSubheading">
                <Text as="p" variant="headingMd">
                  {parseDateLabel(new Date(item.date_end))}
                </Text>
              </div>
            )}
            <ul className="polizzeSectionUl">
              <li className="polizzeSectionLi">
                <div className="polizzeSectionMargin">
                  <div className="polizzeSectionText">
                    <div className="polizzeSectionPoint"></div>
                    <p className="polizzeSectionP">{item.line_items[0].name}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </Link>
      </div>,
    );
  }

  const quotesMarkup = (
    <>
      <div className="polizzeSectionHeading">
        <Text variant="headingMd" as="h2">
          Preventivi
        </Text>
      </div>
      <div>
        <div className="polizzeSectionInitial">
          <div className="polizzeSectionAvatar">
            <Avatar customer={false} size="medium" initials={'PR'} name={'_id'} />
          </div>
        </div>
        <div>{quotesList}</div>
      </div>
    </>
  );

  /**
   * Error markups & toast
   */
  const toastMarkup = active ? <Toast content="I dati sono stati aggiornati con successo." onDismiss={toggleActive} /> : null;
  const toastCustomerDeleted = deleted ? <Toast content="Il cliente è stato eliminato con successo." onDismiss={() => setDeleted(false)} /> : null;

  const toastFileUploadMarkup = fileUploadActive ? <Toast content="Il file è stato caricato con successo" onDismiss={toggleFileUploadActive} /> : null;
  const toastFileDeletedMarkup = fileDeletedActive ? <Toast content="Il file è stato rimosso con successo" onDismiss={toggleFileDeletedActive} /> : null;

  const missingUserMarkup = missingUserError && (
    <Layout.Section>
      <Banner title="Errore" status="critical">
        <p>Il cliente non ha un account utente associato.</p>
      </Banner>
    </Layout.Section>
  );

  const saveErrorMarkup = saveError && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const customerOrdersErrorMarkup = customerOrdersError && (
    <Layout.Section>
      <Banner title="Non è possibile eliminare il cliente" status="critical" onDismiss={() => setCustomerOrdersError(false)}>
        <p>Non è stato possibile procedere poiché il cliente ha associati degli ordini.</p>
      </Banner>
    </Layout.Section>
  );

  /**
   * Page markup
   */
  const actualPageMarkup = (
    <Page
      backAction={{ content: 'Customer', url: '/customers' }}
      title={customer?.user?.lastname ? `${customer.user.lastname} ${customer.user.name}` : customer?.user?.name}
      titleMetadata={renderCustomerStatusBadge(customer?.user?.status ?? 'active')}
      pagination={
        {
          // hasNext: nextUrl && true,
          // hasPrevious: prevUrl && true,
          // nextURL: nextUrl && `/customers/${nextUrl}`,
          // previousURL: prevUrl && `/customers/${prevUrl}`,
        }
      }
      primaryAction={{
        content: 'Crea preventivo/polizza',
        onAction: handleModalQuoteChange,
        disabled: missingUserError,
      }}
      secondaryActions={[
        {
          content: 'Modifica',
          onAction: handleModalChange,
          disabled: missingUserError,
        },
        {
          content: 'Elimina',
          onAction: handleDeleteCustomerModalChange,
        },
      ]}
    >
      <Layout>
        {/* Banner */}
        {missingUserMarkup}
        {saveErrorMarkup}
        {customerOrdersErrorMarkup}
        {/* Panoramica cliente */}
        <Layout.Section>
          <LegacyCard title="Dettagli cliente">
            <LegacyCard.Section>
              <LegacyStack>
                <Text variant="headingMd" as="h2">
                  <Avatar customer={false} size="medium" name={'_id'} />
                </Text>
                <div>
                  <h3>
                    <Text as="span" fontWeight="semibold">
                      {customer?.user?.lastname ? `${customer.user.name} ${customer.user.lastname}` : customer?.user?.name}
                    </Text>
                  </h3>
                  <p>
                    {customer?.type === 'private' && (
                      <Text as="span" color="subdued">
                        Professione: {job ? job : <Badge>Mancante</Badge>}
                      </Text>
                    )}
                  </p>
                  <p>
                    <Text as="span" color="subdued">
                      CF: {customer?.fiscal_code ? customer?.fiscal_code : <Badge>Mancante</Badge>}
                    </Text>
                  </p>
                  <p>
                    <Text as="span" color="subdued">
                      P.IVA: {customer?.vat ? customer?.vat : <Badge>Mancante</Badge>}
                    </Text>
                  </p>
                  <p>
                    <Text as="span" color="subdued">
                      ATECO: {customer?.ateco ? customer?.ateco : <Badge>Mancante</Badge>}
                    </Text>
                  </p>
                  <p>
                    <Text as="span" color="subdued">
                      Cliente dal: {(customer?.date_created ? `${customer.date_created} ` : customer?.date_created)?.toString().split('T')[0]}
                    </Text>
                  </p>
                  <p>
                    <Text as="span" color="subdued">
                      Lingua: {getLanguageLabel(customer?.user?.language ?? 'it')}
                    </Text>
                  </p>
                </div>
              </LegacyStack>
              <br />
              {customer?.notes ? (
                <div className="notaContainer">
                  <LegacyStack>
                    <Labelled id={''} label={undefined}>
                      Note sul cliente:
                    </Labelled>
                    <p>{customer?.notes}</p>
                  </LegacyStack>
                </div>
              ) : (
                ''
              )}
            </LegacyCard.Section>
          </LegacyCard>

          {/* Policies Markup */}
          {policies.length > 0 && <div className="polizzeMarkup">{policiesMarkup}</div>}
          {/* Quotes mMrkup */}
          {quotes.length > 0 && <div className="polizzeMarkup">{quotesMarkup}</div>}

          <LegacyCard title="Documenti vari">
            <LegacyCard.Section>{fileUploadJSX}</LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>

        {/** Second column */}
        <Layout.Section secondary>
          <LegacyCard title="Contatti cliente">
            <LegacyCard.Section>
              {customer?.user?.email ? (
                <p>{customer.user.email}</p>
              ) : (
                <p>
                  <Badge>Mancante</Badge>
                </p>
              )}
            </LegacyCard.Section>
            <LegacyCard.Section title="Telefono">
              <LegacyCard.Subsection>
                <LegacyStack vertical>
                  {customer?.user?.phone ? (
                    <p>{customer?.user.phone}</p>
                  ) : (
                    <p>
                      <Badge>Mancante</Badge>
                    </p>
                  )}
                </LegacyStack>
              </LegacyCard.Subsection>
            </LegacyCard.Section>
            <LegacyCard.Section
              title="Indirizzo"
              actions={[
                {
                  content: customer?.address ? 'Modifica' : 'Aggiungi',
                  onAction: handleModalAddressChange,
                  disabled: missingUserError,
                },
              ]}
            >
              <LegacyCard.Subsection>
                {customer?.address && customer?.address.line.length > 0 ? (
                  <LegacyStack vertical spacing="none">
                    <p>{customer.address.line}</p>
                    <p>
                      {customer.address.zip} {customer.address.city}
                    </p>
                    <p>{countryOptions.find((item) => item.value === customer?.address?.country)?.label ?? <Badge>Mancante</Badge>}</p>
                  </LegacyStack>
                ) : (
                  <p>
                    <Badge>Mancante</Badge>
                  </p>
                )}
              </LegacyCard.Subsection>
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>

        <Layout.Section />
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  /**
   * Error
   */
  const errorPageMarkup = (
    <Page>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <EmptyState
              heading="Nessun cliente presente a questo indirizzo"
              image="https://cdn.shopify.com/shopifycloud/web/assets/v1/08f1b23a19257042c52beca099d900b0.svg"
            >
              <p>Controlla l'URL e riprova oppure usa la barra di ricerca per trovare ciò di cui hai bisogno.</p>
            </EmptyState>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : error ? errorPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {loadingMarkup}
      {pageMarkup}
      {modalMarkup}
      {/* {modalEmailMarkup}
      {modalPhoneMarkup} */}
      {modalAddressMarkup}
      {deleteCustomerMarkup}

      {toastMarkup}
      {toastCustomerDeleted}
      {toastPolicyOrQuoteMarkup}
      {toastFileUploadMarkup}
      {toastFileDeletedMarkup}
      <NewCustomerPolicy
        modalActive={modalQuoteActive}
        handleClose={handleModalQuoteChange}
        customerId={customer?.user?._id}
        setNewPolicyOrQuoteSuccess={setNewPolicyOrQuoteSuccess}
        setIsPolicy={setIsPolicy}
        setUpdated={setUpdate}
      />
    </Frame>
  );
}
