import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  LegacyCard,
  ContextualSaveBar,
  FormLayout,
  Frame,
  Layout,
  Loading,
  Page,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  TextField,
  Autocomplete,
  Icon,
  Banner,
  Toast,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup, contextControlMarkup } from '../../../components';

import './CustomerNew.scss';
import { SearchMinor } from '@shopify/polaris-icons';
import axios, { AxiosError } from 'axios';
import { useUser } from '../../../utils/PrivateRoute';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

export function CustomerNew() {
  const navigate = useNavigate();
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [isDirty, setIsDirty] = useState(true);
  const [active, setActive] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [existError, setExistError] = useState<'fiscal_code' | 'user' | null>(null);

  const conflictErrorMessages = {
    fiscal_code: {
      title: 'Esiste già un cliente associato a questo codice fiscale',
      description: 'Si è pregati di controllare il codice fiscale se si desidera proseguire.',
    },
    user: {
      title: 'Esiste già un cliente associato a questo indirizzo email',
      description: "Si è pregati di controllare l'indirizzo email se si desidera proseguire.",
    },
  };

  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = () => {
    setMobileNavigationActive((data) => !data);
  };

  /**
   * Customer states
   */
  const [customerId, setCustomerId] = useState('');
  const [firstname, setFirstname] = useState(''); //Firstname used as both first name and company name
  const [lastname, setLastname] = useState(''); //Only used if private customer is selected
  const [language, setLanguage] = useState('it');
  const [fiscalCode, setFiscalCode] = useState('');
  const [vat, setVat] = useState(''); //Only used if company customer is selected
  const [ateco, setAteco] = useState(''); //Only used if company customer is selected
  const [job, setJob] = useState('');

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [type, setType] = useState('private'); // Can be either 'private' or 'company'
  const [line, setLine] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('IT');
  const [province, setProvince] = useState('');

  const [countryOptions, setCountryOptions] = useState([]); // Used to load array of countries
  const [notes, setNotes] = useState('');

  /**
   * Empty fields
   */
  const [emptyFields, setEmptyFields] = useState({
    firstname: false,
    lastname: false,
    fiscalCode: false,
    vat: false,
    email: false,
  });

  useEffect(() => {
    console.log(language);
  }, [language]);

  /**
   * Errors
   */
  const [zipError, setZipError] = useState(false);

  const handleDiscard = useCallback(() => {
    setFirstname('');
    setLastname('');
    setEmail('');
    setPhone('');
    setVat('');
    setFiscalCode('');
    setNotes('');
    setType('private');
    setLine('');
    setCity('');
    setZip('');
    setCountry('');
    setIsDirty(true);
  }, []);

  /**
   * Save data
   */
  const handleSave = useCallback(
    async (draft?: boolean) => {
      try {
        // Check firstname & lastname
        if (customerId && !draft && type === 'private' && (firstname === '' || lastname === '' || email === '')) {
          if (firstname === '') setEmptyFields((emptyFields) => ({ ...emptyFields, firstname: true }));
          if (lastname === '') setEmptyFields((emptyFields) => ({ ...emptyFields, lastname: true }));
          if (email === '') setEmptyFields((emptyFields) => ({ ...emptyFields, email: true }));

          setButtonSpinning(false);
          return;
        } else if (customerId && !draft && type === 'company' && (firstname === '' || fiscalCode === '' || vat === '' || email === '')) {
          if (firstname === '') setEmptyFields((emptyFields) => ({ ...emptyFields, firstname: true }));
          if (fiscalCode === '') setEmptyFields((emptyFields) => ({ ...emptyFields, fiscalCode: true }));
          if (vat === '') setEmptyFields((emptyFields) => ({ ...emptyFields, vat: true }));
          if (email === '') setEmptyFields((emptyFields) => ({ ...emptyFields, email: true }));

          setButtonSpinning(false);
          return;
        }

        if (draft) {
          // If the email is not a valid one return until the user completes the email
          if (RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/).test(email) === false) {
            return;
          }
          setIsSavingDraft(true);
        } else {
          setButtonSpinning(true);
        }

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL ?? '/api'}/admin/customers/new`,
          {
            customer_id: customerId,
            draft: draft ?? false,
            name: firstname,
            lastname: lastname,
            email: email.toLowerCase(),
            fiscal_code: fiscalCode,
            vat: vat,
            ateco: ateco,
            job: job,
            type: type,
            phone: phone,
            line: line,
            city: city,
            zip: zip,
            country: country,
            notes: notes,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
          },
        );
        const data = response.data;

        if (data.status === 'success') {
          if (!draft) {
            setIsDirty(false);
            setActive(true);
            setTimeout(() => {
              navigate(`/customers/${data.data._id}`);
            }, 3000);
          } else {
            setCustomerId(data.data._id);
            setIsSavingDraft(false);
          }
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
        const status = axiosError.response?.status || 500;
        const data: any = axiosError.response?.data;
        if (status === 409) {
          setExistError(data.param as 'fiscal_code' | 'user');
        } else {
          setSaveError(true);
        }
      } finally {
        setButtonSpinning(false);
        setIsSavingDraft(false);
      }
    },
    [customerId, firstname, type, lastname, email, fiscalCode, vat, ateco, job, phone, line, city, zip, country, notes, history],
  );

  /**
   * Handle save draft
   */
  useEffect(() => {
    // Save draft only if email and firstname are not empty and when the user is completing the other fields
    if (firstname && email) {
      // Lastname is required only if type is private
      if (type === 'private' && lastname === '') {
        return;
      }

      const debouncedSave = debounce(() => handleSave(true), 2500);
      debouncedSave();
    }
  }, [email, firstname, type, lastname, handleSave]);

  /** Handler */
  const handleFirstnameChange = useCallback(
    (e: string) => {
      setFirstname(e);
      if (emptyFields.firstname) setEmptyFields((emptyFields) => ({ ...emptyFields, firstname: false }));
    },
    [emptyFields.firstname],
  );

  const handleLastnameChange = useCallback(
    (e: string) => {
      setLastname(e);
      if (emptyFields.lastname) setEmptyFields((emptyFields) => ({ ...emptyFields, lastname: false }));
    },
    [emptyFields.lastname],
  );

  const handleFiscalCodeChange = useCallback(
    (e: string) => {
      setFiscalCode(e);
      if (emptyFields.fiscalCode) setEmptyFields((emptyFields) => ({ ...emptyFields, fiscalCode: false }));
    },
    [emptyFields.fiscalCode],
  );

  // Email
  const handleEmailChange = useCallback(
    (e: string) => {
      if (emptyFields.email) setEmptyFields((emptyFields) => ({ ...emptyFields, email: false }));
      setEmail(e.toLowerCase());
    },
    [emptyFields.email],
  );

  // Phone
  const handlePhoneChange = useCallback((e: string) => {
    setPhone(e);
  }, []);

  // Vat
  const handleVatChange = useCallback(
    (e: string) => {
      if (emptyFields.vat) setEmptyFields((emptyFields) => ({ ...emptyFields, vat: false }));

      // Allow only numbers
      if (e.match(/^[0-9]*$/)) {
        setVat(e);
      }
    },
    [emptyFields.vat],
  );

  const handleAtecoChange = useCallback((e: any) => {
    setAteco(e);
  }, []);

  const handleTypeChange = useCallback(
    (e: any) => {
      // Reset empty fields depending on type
      if (type === 'private') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, firstname: false, lastname: false, email: false }));
      } else {
        setEmptyFields((emptyFields) => ({ ...emptyFields, firstname: false, fiscalCode: false, vat: false, email: false }));
      }

      setType(e);
    },
    [type],
  );

  const handleLanguageChange = useCallback((e: any) => {
    if (e === 'it' || e === 'en' || e === 'fr' || e === 'es' || e === 'de') {
      setLanguage(e);
    }
  }, []);

  // Line
  const handleLineChange = useCallback((e: string) => {
    setLine(e);
  }, []);

  // City
  const handleCityChange = useCallback((e: string) => {
    setCity(e);
  }, []);

  // Zip
  const handleZipChange = useCallback(
    (e: string) => {
      if (zipError) {
        setZipError(false);
      }

      // Allow only numbers
      if (e.match(/^[0-9]*$/)) {
        setZip(e);
      }

      // Allow only 5 numbers
      if (e.length > 5) {
        setZip(e.substring(0, 5));
      }
    },
    [zipError],
  );

  // Country
  const handleCountryChange = useCallback((e: any) => {
    setCountry(e);
  }, []);

  const handleNotesChange = useCallback((e: string) => {
    setNotes(e);
  }, []);

  // Used to set type of customer options
  const options = [
    { label: 'Privato', value: 'private' },
    { label: 'Azienda', value: 'company' },
  ];

  // Language
  const languages = [
    { label: 'Italiano', value: 'it' },
    { label: 'Inglese', value: 'en' },
    { label: 'Francese', value: 'fr' },
    { label: 'Spagnolo', value: 'es' },
    { label: 'Tedesco', value: 'de' },
  ];

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Modifiche non salvate"
      saveAction={{
        onAction: () => handleSave(false),
        loading: buttonSpinning,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
      contextControl={contextControlMarkup}
    />
  ) : null;

  const loadingMarkup = isLoading ? <Loading /> : null;

  /**
   * Load countries, called only once when mounting components
   */
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL ?? '/api'}/countries`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setCountryOptions(data.data);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
        const status = axiosError.response?.status || 500;
        const data: any = axiosError.response?.data;
      } finally {
        setIsLoading(false);
      }
    };
    fetchCountries();
  }, []);

  /**
   * Search jobs
   */
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [jobOptions, setJobOptions] = useState([]);

  /**
   * Search ateco
   */
  const [selectedAtecoOptions, setSelectedAtecoOptions] = useState([]);
  const [inputAtecoValue, setInputAtecoValue] = useState('');
  const [deselectedAtecoOptions, setDeselectedAtecoOptions] = useState([]);
  const [atecoOptions, setAtecoOptions] = useState([]);

  /**
   * Fetch data:
   * - jobs
   * - ateco codes
   */
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        setIsLoading(true);
        const response = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/jobs', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();

        if (data.status === 'success') {
          const tmp = [];
          for (const item of data.data) {
            tmp.push({ value: item._id, label: item.label });
          }
          // @ts-ignore
          setDeselectedOptions(tmp);
          // @ts-ignore
          setJobOptions(tmp);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchAteco = async () => {
      try {
        setIsLoading(true);
        const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/ateco', {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const response = await data.json();

        if (response.status === 'success') {
          const tmp = [];
          for (const item of response.data) {
            tmp.push({ value: item._id, label: item.label });
          }
          // @ts-ignore
          setDeselectedAtecoOptions(tmp);
          // @ts-ignore
          setAtecoOptions(tmp);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchJobs();
    // fetchAteco();
  }, []);

  /**
   * Autocomplete Controls
   */

  /** Job */
  const updateText = useCallback(
    (value: any) => {
      setInputValue(value);

      if (value === '') {
        setJobOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = deselectedOptions.filter((option) => {
        // @ts-ignore
        return option.label.match(filterRegex);
      });
      setJobOptions(resultOptions);
    },
    [deselectedOptions],
  );

  const updateSelection = useCallback(
    (selected: any) => {
      const selectedValue = selected.map((selectedItem: any) => {
        const matchedOption = jobOptions.find((option) => {
          // @ts-ignore
          return option.value.match(selectedItem);
        });
        // @ts-ignore
        return matchedOption;
      });
      setSelectedOptions(selected);
      setInputValue(selectedValue[0].label);
      // handleCustomerNameChange(selected);
      // handleCustomerIdChange(selectedValue[0].value);
      setJob(selectedValue[0].value);
    },
    [jobOptions],
  );

  const jobTextField = (
    <Autocomplete.TextField
      autoComplete="off"
      onChange={updateText}
      label="Professione"
      value={inputValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca"
    />
  );

  /** Ateco */
  const updateAtecoText = useCallback(
    (value: any) => {
      setInputAtecoValue(value);

      if (value === '') {
        setAtecoOptions(deselectedAtecoOptions);
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = deselectedAtecoOptions.filter((option) => {
        // @ts-ignore
        return option.label.match(filterRegex);
      });
      setAtecoOptions(resultOptions);
    },
    [deselectedAtecoOptions],
  );

  const updateAtecoSelection = useCallback(
    (selected: any) => {
      const selectedValue = selected.map((selectedItem: any) => {
        const matchedOption = atecoOptions.find((option) => {
          // @ts-ignore
          return option.value.match(selectedItem);
        });
        // @ts-ignore
        return matchedOption;
      });
      setSelectedAtecoOptions(selected);
      setInputAtecoValue(selectedValue[0].label);
      // handleCustomerNameChange(selected);
      // handleCustomerIdChange(selectedValue[0].value);
      setAteco(selectedValue[0].value);
    },
    [atecoOptions],
  );

  const atecoTextField = (
    <Autocomplete.TextField
      autoComplete="off"
      onChange={updateAtecoText}
      label="ATECO"
      value={inputAtecoValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca"
    />
  );

  /**
   * Error markups & toast
   */
  const toastMarkup = active ? <Toast content="Il cliente è stato creato con successo." onDismiss={toggleActive} /> : null;

  const saveErrorMarkup = saveError && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const existErrorMarkup = existError && (
    <Layout.Section>
      <Banner title={conflictErrorMessages[existError].title} status="critical" onDismiss={() => setExistError(null)}>
        <p>{conflictErrorMessages[existError].description}</p>
      </Banner>
    </Layout.Section>
  );

  // ---- Page markup ----
  const actualPageMarkup = (
    <Page title="Cliente" backAction={{ content: 'Clienti', url: '/customers' }}>
      <Layout>
        {/* Banner */}
        {saveErrorMarkup}
        {existErrorMarkup}
        {/* Panoramica cliente */}
        <Layout.AnnotatedSection title="Panoramica cliente">
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout.Group>
                <Select label="Tipologia cliente" options={options} onChange={handleTypeChange} value={type} />
              </FormLayout.Group>
              <FormLayout.Group>
                {/* Logic to display either private's or company's naming info */}
                {type === 'private' && (
                  <TextField
                    autoComplete="on"
                    type="text"
                    label="Nome"
                    value={firstname}
                    onChange={handleFirstnameChange}
                    error={emptyFields.firstname && 'Il nome è obbligatorio'}
                  />
                )}
                {type === 'private' && (
                  <TextField
                    autoComplete="on"
                    type="text"
                    label="Cognome"
                    value={lastname}
                    onChange={handleLastnameChange}
                    error={emptyFields.lastname && 'Il cognome è obbligatorio'}
                  />
                )}
                {type === 'company' && (
                  <TextField
                    autoComplete="on"
                    type="text"
                    label="Ragione Sociale"
                    value={firstname}
                    onChange={handleFirstnameChange}
                    error={emptyFields.firstname && 'La regione sociale è obbligatoria'}
                  />
                )}
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  autoComplete="on"
                  type="text"
                  label="Codice Fiscale"
                  value={fiscalCode}
                  onChange={handleFiscalCodeChange}
                  error={emptyFields.fiscalCode && 'Il codice fiscale è obbligatorio'}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                {type === 'private' && (
                  <Autocomplete options={jobOptions} selected={selectedOptions} onSelect={updateSelection} textField={jobTextField} loading={isLoading} />
                )}
              </FormLayout.Group>
              <FormLayout.Group>
                {/* Logic checks if vat is to be displayed */}
                {type === 'company' && (
                  <TextField
                    autoComplete="on"
                    type="text"
                    label="Partita IVA"
                    value={vat}
                    onChange={handleVatChange}
                    error={emptyFields.vat && 'La P.IVA è obbligatoria'}
                  />
                )}
                {type === 'company' && (
                  <Autocomplete options={atecoOptions} selected={selectedAtecoOptions} onSelect={updateAtecoSelection} textField={atecoTextField} />
                )}
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  autoComplete="on"
                  type="email"
                  label="Email"
                  value={email}
                  onChange={handleEmailChange}
                  error={emptyFields.email && "L'email è obbligatoria"}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField autoComplete="on" type="tel" label="Numero di telefono" value={phone} onChange={handlePhoneChange} />
                <Select label="Lingua" options={languages} onChange={handleLanguageChange} value={language} />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>

        {/* Indirizzo */}
        <Layout.AnnotatedSection title="Indirizzo" description="L'indirizzo principale di questo cliente">
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout.Group>
                <Select label="Stato" options={countryOptions} onChange={handleCountryChange} value={country} />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField autoComplete="on" type="text" label="Indirizzo" value={line} onChange={handleLineChange} />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField autoComplete="on" type="text" label="CAP" value={zip} onChange={handleZipChange} error={zipError && 'Controllare il CAP'} />
                <TextField autoComplete="on" type="text" label="Città" value={city} onChange={handleCityChange} />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>

        {/* Note */}
        <Layout.AnnotatedSection title="Note">
          <LegacyCard sectioned>
            <TextField autoComplete="on" type="text" label="Note Sul Cliente" value={notes} onChange={handleNotesChange} multiline={6} />
          </LegacyCard>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {contextualSaveBarMarkup}
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </Frame>
  );
}
